import {defaultShapeOptions} from './drawing_manager'

var mapConverters = {
  shapeProperties: [
    { strokeColor: '#161E45', strokeOpacity: 0.3, strokeWeight: 1, fillColor: '#4955F4', fillOpacity: 0.5 },
    { strokeColor: '#AB2424', strokeOpacity: 0.3, strokeWeight: 1, fillColor: '#FF1D1D', fillOpacity: 0.5 },
    { strokeColor: '#083735', strokeOpacity: 0.3, strokeWeight: 1, fillColor: '#0E7069', fillOpacity: 0.5 },
    { strokeColor: '#6F630E', strokeOpacity: 0.3, strokeWeight: 1, fillColor: '#FF8C00', fillOpacity: 0.5 },
    { strokeColor: '#7D1E8F', strokeOpacity: 0.3, strokeWeight: 1, fillColor: '#C33FFD', fillOpacity: 0.5 },
    { strokeColor: '#502F09', strokeOpacity: 0.3, strokeWeight: 1, fillColor: '#B66E1C', fillOpacity: 0.5 },
  ],
  shapePropertiesIndex: -1,

  getNextShapeProperties: function() {
    mapConverters.shapePropertiesIndex += 1;
    if (mapConverters.shapePropertiesIndex >= mapConverters.shapeProperties.length)
      mapConverters.shapePropertiesIndex = 0;
    return mapConverters.shapeProperties[mapConverters.shapePropertiesIndex];
  },

  getShapePropertiesByColor: function(color) {
    if (color == null) {
      return mapConverters.getNextShapeProperties();
    }

    if (color.indexOf("#") != 0) {
      color = "#" + color;
    }
    for(var index = 0; index < mapConverters.shapeProperties.length; index++) {
      if (mapConverters.shapeProperties[index].fillColor == color) {
        mapConverters.shapePropertiesIndex = index;
        return mapConverters.shapeProperties[mapConverters.shapePropertiesIndex];
      }
    }
    return {strokeColor: color, strokeOpacity: 0.3, strokeWeight: 1, fillColor: color, fillOpacity: 0.5};
  },

  defaultDrawingProperties: { strokeWeight: 2, strokeOpacity: 0.7},

  /**
   * @param  {array}    An array of google.maps.LatLng objects
   * @return {string}   latLngString ex: "-34 151,48 -21"
   */ 
  getStringFromPoints: function(points) {
    var pointStringArray = [];
    points.forEach(function(point){
      pointStringArray.push( parseFloat(point.lat().toFixed(6)) + " " + parseFloat(point.lng().toFixed(6)) );
    });
    return pointStringArray.join(',');
  },


  /**
   * @param  {string}   latLngString ex: "-34 151,48 -21"
   * @return {array}    An array of google.maps.LatLng objects
   */
  getPointsFromLatLngString: function(latLngString) {
    var points = [];
    latLngString.split(',').forEach(function(latLngString) {
      points.push( new google.maps.LatLng( parseFloat(latLngString.split(' ')[0]), parseFloat(latLngString.split(' ')[1]) ) );
    });
    return points;
  },

  /**
   * @param  {google.maps.LatLngBounds}   mapBounds
   * @return {string}
   */
  mapBoundsToSparkString: function(mapBounds) {
    if (typeof mapBounds !== 'undefined' ){
      var northEast = mapBounds.getNorthEast();
      var neLat = northEast.lat().toFixed(6); // toFixed handles floating point problems
      var neLng = northEast.lng().toFixed(6);

      var southWest = mapBounds.getSouthWest();
      var swLat = southWest.lat().toFixed(6);
      var swLng = southWest.lng().toFixed(6);

      var sparkString = "rectangle('" + neLat + " " + neLng + "," + swLat + " " + swLng + "')";
      return sparkString;
    }
  },

  /**
   * @param   {string}                     ex: "polygon('47.598962 -96.917222,47.598962 -94.825651,46.325567 -94.825651,46.325567 -96.917222')"
   * @return  {google.maps.LatLngBounds}   mapBounds
   */
  sparkShapeToGoogleBounds: function(sparkShapeString) {
    var boundaryPoints = mapConverters.sparkBoundaryToPoints(sparkShapeString);
    return mapConverters.getGoogleBoundsFromPoints(boundaryPoints);
  },
  
  /**
   * @param  {array}                      pointsArray   An array of google.maps.LatLng objects or objects with
   *                                                    the keys lat and lng
   * @return {google.maps.LatLngBounds}
   */
  getGoogleBoundsFromPoints: function(pointsArray){
    var minLat = 90;
    var minLng = 180;
    var maxLat = -90;
    var maxLng = -180;
    // Don't return bounds that are smaller than the min distance in lat and lng.
    // For example, if pointsArray only includes one point, or multiple points
    // that are very close, like in the same apartment building, we'd need to 
    // add a buffer to the bounds. Using a fixed buffer will give us an inconsistent
    // buffer at different latitudes, but it's probably fine.
    var minDistance = 0.005;

    pointsArray.forEach(function(latLng) {
      if( typeof latLng.lat !== 'function' ){
        latLng = new google.maps.LatLng(latLng.lat, latLng.lng);
      }

      if (latLng.lat() < minLat) { minLat = latLng.lat(); }
      if (latLng.lat() > maxLat) { maxLat = latLng.lat(); }
      if (latLng.lng() < minLng) { minLng = latLng.lng(); }
      if (latLng.lng() > maxLng) { maxLng = latLng.lng(); }
    });

    if(Math.abs(minLat - maxLat) < minDistance) {
      minLat = minLat - minDistance/2;
      maxLat = maxLat + minDistance/2;
    }

    if(Math.abs(minLng - maxLng) < minDistance) {
      minLng = minLng - minDistance/2;
      maxLng = maxLng + minDistance/2;
    }

    var southWest = new google.maps.LatLng(minLat, minLng);
    var northEast = new google.maps.LatLng(maxLat, maxLng);

    return new google.maps.LatLngBounds(southWest, northEast);
  },

  /**
   * @param  {string}   A spark shape string, ex: "rectangle('48 -21,-34 151')"
   * @return {array}    An array of google.maps.LatLng objects
   */
  sparkBoundaryToPoints: function(sparkBoundary) {
    var latLngString;
    if(sparkBoundary.indexOf("'") > -1 ){
      latLngString = sparkBoundary.split("'")[1];
    } else {
      latLngString = sparkBoundary.split(/\(|\)/g)[1];
    }
    return mapConverters.getPointsFromLatLngString(latLngString);
  },

  /**
   * @param  {array}  An array of google.maps.LatLng objects
   * @return {array}  An array of js objects where x is the longitude and y is the latitude
   */
  googlePointsToXYPoints: function(googlePointsArray) {
    var newArray = [];
    googlePointsArray.forEach(function(point){
      newArray.push( { x: point.lng(), y: point.lat() } );
    });
    return newArray;
  },

  /**
   * @param  {object}  A parsed Spark expression from Filter.js
   * @return {object}  A google.maps shape
   */
  getGoogleShapeFromFilterShape: function(element) {
    var shape;
    var points = mapConverters.getPointsFromLatLngString(element.function_parameters[0]);
    var shapeOptions = $.extend({}, defaultShapeOptions);

    switch (element.function_name) {
      case 'polygon':
        shapeOptions.path = points;
        shape = new google.maps.Polygon(shapeOptions);
        break;
      case 'rectangle':
        var bounds;
        var swPoint;
        var nePoint;
        // Make sure the south west and north east points are properly defined.
        // Our search can make a rectangle of the 2 points no matter where they are.
        // But google cannot draw them correctly without these points properly defined.
        if (points[0].lat() > points[1].lat()) {
          if (points[0].lng() > points[1].lng()) {
            swPoint = points[1];
            nePoint = points[0];
          } else {
            swPoint = new google.maps.LatLng(points[1].lat(), points[0].lng());
            nePoint = new google.maps.LatLng(points[0].lat(), points[1].lng());
          }
        } else {
          if (points[0].lng() < points[1].lng()) {
            swPoint = points[0];
            nePoint = points[1];
          } else
            swPoint = new google.maps.LatLng(points[0].lat(), points[1].lng())
            nePoint = new google.maps.LatLng(points[1].lat(), points[0].lng());
        }
        bounds = new google.maps.LatLngBounds(swPoint, nePoint);
        shapeOptions.bounds = bounds;
        shape = new google.maps.Rectangle(shapeOptions);
        break;
      case 'radius':
        var radius = mapConverters.milesToMeters(element.function_parameters[1]);
        shapeOptions.center = points[0];
        shapeOptions.radius = radius;
        shape = new google.maps.Circle(shapeOptions);
        break;
    }
    shape.set('condition', element.condition);
    return shape;
  },

  milesToMeters: function(miles) {
    return miles * 1609.34;
  }

};

export default mapConverters;
