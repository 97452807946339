import { Controller } from "stimulus";
import {getState, setState} from '../search_results/state'


export default class extends Controller {
  static values = {
    flagship: String
  }

  routePostMessage(e) {
    if (e.origin === this.flagshipValue) {
      let method = typeof e.data === 'string' ? e.data.split(':')[0] : undefined;
      switch (method) {
        case "closeCMA":
          $('#cma_modal').modal('toggle');
          break;
        case "setCMATitle":
          $('#cma_modal #modal-title').text(e.data.split(':')[1]);
          break;
        case "resetCMAButtons":
          $('#cma_modal #native_buttons').html("");
          break;
        case "addCMAButton":
          let data = e.data.split(':');
          let text = data[1];
          let id = data[2];
          let classname = data[3];
          let submit = "document.getElementById('cma_iframe').contentWindow.postMessage('" + data[4] + "', '" + this.flagshipValue + "')";
          $('#cma_modal #native_buttons').append('<button id="' + id + '" class="' + classname + '" onclick="' + submit + '">' + text + '</button>');
          break;
        case "savedCMA":
          $('#cma_modal').modal('toggle');
          toast.success("CMA saved successfully.");
          break;
      }
    }
  }
  
  showCMAAll(evt) {
    evt.preventDefault();
    $('#cma_type').modal('toggle');
    this.resetCMAModal();

    let modal = $('#flagship_modal_content');
    let iframe = document.createElement('iframe');
    iframe.id = "cma_iframe";
    iframe.src = this.flagshipValue + "/cgi-bin/mainmenu.cgi?cmd=url+search/reports/cma/index.html" + '&_filter=' + getState('filter');
    $(modal).empty();
    $(modal).append(iframe);
    $('#cma_modal').modal('toggle');
  }

  showCMASelected(evt) {
    evt.preventDefault();
    $('#cma_type').modal('toggle');
    this.resetCMAModal();

    let modal = $('#flagship_modal_content');
    $(modal).empty();

    let filter = "&_filter=" + getState('filter');
    let selected = $('.selectListingsControl:checked');
    if (selected.length == 0) {
      alert('Please select at least one listing to generate a CMA');
      return;
    }
    let listings = selected.toArray().map((sel) => sel.id.split('_')[1]);
    let rs = "&rs=" + listings.join("&rs=");
    let db2 = "&tb1=list&f1=tech_id&o1=in&c1=x'" + listings.join("', x'") + "'";
    let qcount = "&qcount=1";

    let iframe = document.createElement('iframe');
    iframe.id = "cma_iframe";
    iframe.src = this.flagshipValue + "/cgi-bin/mainmenu.cgi?cmd=url+search/reports/cma/index.html" + rs+db2+qcount+filter;
    $(modal).append(iframe);
    $('#cma_modal').modal('toggle');
  }

  resetCMAModal() {
    $('#cma_modal #modal-title').text("");
    $('#cma_modal #native_buttons').html("");
  }

}