import Overlay from './map/overlay'
import OverlayShape from './map/overlay_shape'
import OverlayMapType from './map/overlay_map_type'
import mapConverters from './map/map_converters'

class GlobalOverlayManager {
  constructor() {
    if (!GlobalOverlayManager.instance) {
      GlobalOverlayManager.instance = this;
      this.data = {
        googleMap: null,
        // An object where the overlays will be referenced by the key name
        overlays: {},
        // An array of keys that reference the overlays object
        selectedMyMapOverlays: [],
        // An array of objects with mls overlay id and layer name
        selectedMlsOverlays: [],
        // Google shapes
        overlaysOnTheMap: [],
        filteredOverlaysOnTheMap: {},
        filteredOverlayShapesOnTheMap: {}
      }
    }
    return GlobalOverlayManager.instance;
  }

  /* --------------------
    MLS Overlays
  -------------------- */
  addMlsOverlay(overlayId, overlayName) {
    const existingOverlay = this.data.selectedMlsOverlays.some(obj => obj.Id === overlayId);

    if (!existingOverlay) {
      this.data.selectedMlsOverlays.push({ Id: overlayId, LayerName: overlayName });
      this.data.googleMap.overlayMapTypes.push(new OverlayMapType(overlayName));
    }
  }

  removeMlsOverlay(overlayId) {
    const index = this.data.selectedMlsOverlays.findIndex(obj => obj.Id === overlayId);

    if (index !== -1) {
      this.data.selectedMlsOverlays.splice(index, 1);
      this.data.googleMap.overlayMapTypes.removeAt(index);
    }
  }

  getSelectedMlsOverlays() {
    return this.data.selectedMlsOverlays;
  }

  loadSelectedMlsOverlays() {
    // This should only be done when initializing the map.
    let self = this
    this.data.selectedMlsOverlays.forEach(overlay => {
      self.data.googleMap.overlayMapTypes.push(new OverlayMapType(overlay.LayerName));
    })
  }


  /* --------------------
    My Map Overlays
  -------------------- */
  async getMyMapOverlay(overlayId) {
    // Given an ID, retrieve the overlay from the global manager's
    // storage or from the server.
    if (this.hasOverlay(overlayId)) {
      // We already retrieved this information.  Return it
      return this.data.overlays[overlayId];
    } else {
      // Go get the information we desire
      try {
        const request = new Request(window.routes.overlay_path(overlayId), { method: 'GET' });
        const response = await fetch(request);
        const json = await response.json();
        if (json.success) {
          this.data.overlays[overlayId] = new Overlay(JSON.parse(json.data).attributes);
          return this.data.overlays[overlayId];
        } else {
          return null;
        }
      } catch (error) {
        console.error('Error fetching overlay:', error);
        return null;
      }
    }
  }

  addMyMapOverlay(overlay) {
    // Add an overlay to storage (if it does not already exist)
    if (!this.hasOverlay(overlay.Id)) {
      this.data.overlays[overlay.Id] = overlay;
    }
  }

  addMyMapOverlayToMap(overlay, opts = {}) {
    // Add the overlay to the map
    // But first, make sure it is in our list overlays
    this.addMyMapOverlay(overlay)

    if (!this.data.selectedMyMapOverlays.includes(overlay.Id)) {
      this.data.selectedMyMapOverlays.push(overlay.Id);

      var self = this;
      overlay.Shapes.forEach(function(overlayShape) {
        self._addShapeToMap(overlayShape, opts.color)
      });
    }
  }

  removeMyMapOverlayFromMap(overlayId, color) {
    // Remove the overlay id from the 'selected' array
    this.data.selectedMyMapOverlays = this.data.selectedMyMapOverlays.filter(item => item !== overlayId)

    let key = this._storageKey(overlayId, color);

    // Remove the map entities
    this.data.overlaysOnTheMap[key].forEach(function(googleShape) {
      googleShape.setMap(null)
    });
    delete this.data.overlaysOnTheMap[key];
  }

  addFilterOverlayToMap(overlay) {
    if (overlay.Id in this.data.filteredOverlaysOnTheMap) {
      return;
    }
    for (let x = 0; x < overlay.Shapes.length; x++) {
      let shape = overlay.Shapes[x];
      this.addFilterOverlayShapeToMap(overlay.Id, shape)
    }
  }

  removeFilterOverlayFromMap(overlayId) {
    if (overlayId in this.data.filteredOverlaysOnTheMap) {
      // Remove the map entities
      this.data.filteredOverlaysOnTheMap[overlayId].forEach(function(googleShape) {
        googleShape.setMap(null)
      });
      delete this.data.filteredOverlaysOnTheMap[overlayId];
    }
  }

  addFilterOverlayShapeToMap(key, shape) {
    let options = {}
    let useOverlayArray = key !== shape.Id
    let workingObject = useOverlayArray ? this.data.filteredOverlaysOnTheMap : this.data.filteredOverlayShapesOnTheMap
    let keyExists = (key in workingObject)

    // If it is an overlay key, we dont care if it exists.  We want to add multiple shapes here.
    // If not an overlay key, then check to see if the shape key exists and skip the work if it does.
    if (useOverlayArray || !keyExists) {

      options = {
        strokeColor: '#' + shape.Color,
        fillColor: '#' + shape.Color,
        fillOpacity: 0.15
      };

      let googleShape = this._buildGoogleShape(shape, options);
      googleShape.setMap(this.data.googleMap);

      if (!keyExists) {
        workingObject[key] = []
      }
      workingObject[key].push(googleShape)
    }
  }

  removeFilterOverlayShapeFromMap(shapeId) {
    if (shapeId in this.data.filteredOverlayShapesOnTheMap) {
      // Remove the map entities
      this.data.filteredOverlayShapesOnTheMap[shapeId].forEach(function(googleShape) {
        googleShape.setMap(null)
      });
      delete this.data.filteredOverlayShapesOnTheMap[shapeId];
    }
  }

  clearAllFilteredShapes() {
    let self = this
    Object.keys(this.data.filteredOverlaysOnTheMap).forEach(overlayId => {
      self.data.filteredOverlaysOnTheMap[overlayId].forEach(function(googleShape) {
        googleShape.setMap(null)
      });
      delete self.data.filteredOverlaysOnTheMap[overlayId];
    });

    Object.keys(this.data.filteredOverlayShapesOnTheMap).forEach(shapeId => {
      self.data.filteredOverlayShapesOnTheMap[shapeId].forEach(function(googleShape) {
        googleShape.setMap(null)
      });
      delete self.data.filteredOverlayShapesOnTheMap[shapeId];
    });
  }

  _addShapeToMap(overlayShape, color) {
    let options = {};

    if(typeof color !== 'boolean'){
      color = true;
    }

    if(!color){
      options = {
        strokeColor: '#FFFFFF',
        fillColor: '#000000',
        fillOpacity: 0.15
      };
    }

    let googleShape = this._buildGoogleShape(overlayShape, options);
    googleShape.setMap(this.data.googleMap);

    let key = this._storageKey(overlayShape.OverlayId, color);

    if(typeof this.data.overlaysOnTheMap[key] === 'undefined' ){
      this.data.overlaysOnTheMap[key] = [];
    }

    this.data.overlaysOnTheMap[key].push(googleShape);
  }

  loadSelectedMyMapOverlays() {
    // This should only be done when initializing the map.
    for (let x = 0; x < this.data.selectedMyMapOverlays.length; x++) {
      let overlay = this.data.overlays[this.data.selectedMyMapOverlays[x]]
      for (let y = 0; y < overlay.Shapes.length; y++) {
        this._addShapeToMap(overlay.Shapes[y], false)
      }
    }
  }


  /* --------------------
    My Map Overlay Shapes
  -------------------- */

  async getMyMapOverlayShape(shapeId) {
    // We have a shape ID. Look for it in our stored overlays
    for(let key in this.data.overlays) {
      const shape = this.data.overlays[key].Shapes.find(shape => shape.Id === shapeId.replace(/^'|'$/g, ''));
      if (shape) {
        return shape;
      }
    }
    // We didnt find anything loaded in our overlay memory.  Go get the individual shape information
    let shapes = await OverlayShape.find("'" + shapeId + "'")
    if (shapes.length >= 1) {
      return shapes[0];
    }
    return null;
  }

  async getMyMapOverlayShapeFromOverlay(shapeId, overlayId) {
    // Look for a shape by its ID/overlay ID.  If we don't
    // already have it stored, load it.
    if (this.hasOverlay(overlayId)) {
      const shape1 = this.data.overlays[overlayId].Shapes.find(shape => shape.Id === shapeId.replace(/^'|'$/g, ''));
      return shape1;
    } else {
      const overlay = await this.getMyMapOverlay(overlayId);
      if (overlay) {
        const shape2 = this.data.overlays[overlayId].Shapes.find(shape => shape.Id === shapeId.replace(/^'|'$/g, ''));
        return shape2;
      }
    }
    // Couldn't find the shape.
    return null;
  }

  fillOverlayShape(shapeId, fillValue) {
    if (shapeId in this.data.filteredOverlayShapesOnTheMap) {
      this.data.filteredOverlayShapesOnTheMap[shapeId].forEach(function(googleShape) {
        googleShape.set('fillOpacity',fillValue);
      });
    }
  }


  /* --------------------
    The google map
  -------------------- */

  setMap(value) {
    this.data.googleMap = value
  }

  getMap() {
    return this.data.googleMap
  }

  _buildGoogleShape(overlayShape, options) {
    if(typeof options === 'undefined') {
      options = {};
    }
    let points = mapConverters.sparkBoundaryToPoints(overlayShape.Geometry);
    let color = '#' + overlayShape.Color;
    let defaults = {
      path: points,
      strokeColor: color,
      strokeWeight: 1,
      fillColor: color,
      fillOpacity: 0.1,
      clickable: false
    };
    let args = $.extend({}, defaults, options);

    return new google.maps.Polygon(args);
  }

  _storageKey(overlayId, color) {
    return (color) ? overlayId : overlayId + "Grayscale";
  }

  hasOverlay(overlayId) {
    return (Object.keys(this.data.overlays).includes(overlayId))
  }

  getTotalMapOverlayShapeBounds() {
    let bounds = null;
    let self = this;
    Object.keys(this.data.filteredOverlayShapesOnTheMap).forEach(shapeId => {
      self.data.filteredOverlayShapesOnTheMap[shapeId].forEach(function(googleShape) {
        let pointsArray = googleShape.getPath();
        if (bounds === null) {
          bounds = mapConverters.getGoogleBoundsFromPoints(pointsArray)
        } else {
          bounds.union(mapConverters.getGoogleBoundsFromPoints(pointsArray))
        }
      });
    });
    return bounds;
  }

  removeAllOverlaysFromMap() {
    var self = this;
    Object.keys(this.data.filteredOverlayShapesOnTheMap).forEach(shapeId => {
      self.data.filteredOverlayShapesOnTheMap[shapeId].forEach(function(googleShape) {
        let pointsArray = googleShape.getPath();
        if (bounds === null) {
          bounds = mapConverters.getGoogleBoundsFromPoints(pointsArray)
        } else {
          bounds.union(mapConverters.getGoogleBoundsFromPoints(pointsArray))
        }
      });
    });
  }
}

const instance = new GlobalOverlayManager();
Object.freeze(instance)
export default instance;
