import routes from '../routes'
import {markerManager} from './marker_manager'

var nosyNeighbor = (function() {

  var googleMap;
  var triggerZoomLevel = 17;
  var flexmlsMap;

  function init(flexmls_map, google_map) {
    flexmlsMap = flexmls_map
    googleMap = google_map;
    google.maps.event.addListener(googleMap, 'click', nosyNeighborClickListener);
  }

  function outlineParcel(property) {
    if (property) {
      // Make parcel outline from mapdb data
      var shape = property.polygons.shape;
      if (shape.length > 1) {
        shape = shape[0];
      }

      var polygons = [];
      for (var i = 0; i < shape.point.length; i++) {
        polygons.push({lat: parseFloat(shape.point[i].lat), lng: parseFloat(shape.point[i].lon)})
      }

      flexmlsMap.drawParcelOutline(polygons);
    }
  }

  function nosyNeighborClickListener(event) {

    if (googleMap.zoom < triggerZoomLevel) {
      return;
    }
    flexmlsMap.deleteParcelOutlines();

    var lat = event.latLng.lat();
    var lng = event.latLng.lng();

    if(typeof lat !== 'number' || typeof lng !== 'number') {
      return;
    }

    var url = routes.nosy_neighbor_path({lat: lat, lon: lng})

    $.get(url, function(data) {
      
      var property = data.mapdb_res.response.property;

      if (data.StandardFields) {
        outlineParcel(property);
        var listingData = {
          listingId: data.Id,
          listingStatus: data.StandardFields.StandardStatus,
          lat: data.StandardFields.Latitude,
          lng: data.StandardFields.Longitude
        }
        markerManager.addPreviewMarker(listingData, {keepAlive: true, showSlideshow: true});
      } else if (property && property.fullstreetaddress !== null) {
        outlineParcel(property);
        var opts = {
          address: property.fullstreetaddress,
          listingId: property.pin,
          lat: data.mapdb_res.response.lat,
          lng: data.mapdb_res.response.lon,
        }

        markerManager.addNosyNeighborMarker(opts);
      }
    }).fail(function(e) {
      toast.notice("No Properties found");
    });
  }

  return {
    init: init
  };

})();

export default nosyNeighbor;
