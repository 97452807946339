import Cookies from 'js-cookie';
import Url from 'domurl';
import clonedeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';

import Filter from './../filter';
import {shouldUseDesktopView} from './helpers';
import {MapPositionStorage} from './map_position_storage';
import mapConverters from '../map/map_converters';
import { nativeAppInterface } from '../native_app_interface';

let store;
const debug = false;

export function _initializeStore(force) {
  if(typeof store === 'undefined' || force){
    store = {
      desktopSearchResultsView: initialDesktopView(),  // list or split
      filter: new Filter(window.mapSupport.parsedFilter, window.mapSupport.locationFields),
      annotations: window.mapSupport.annotations,
      listingCountTotal: window.mapSupport.totalItems,
      listingCountVisible: window.mapSupport.totalItems,
      mapBounds: initialMapBounds(),
      mobileSearchResultsView: initialMobileView(),  // list or map
      sortId: $('#listingsSort .selectedSort').attr('data-sort-id'),
      userViewId: $('#userViews .selectedView').attr('data-view-id'),
      saveListUpdated: false
    };
    log("initial state set");
    log(store);
  }
}

export function emptyStore() {
  store = undefined;
}

function initialMobileView() {
  let url = new Url();
  let parts = url.path.split('/');
  return parts[parts.length-1] === 'map' ? 'map' : 'list';
}

function initialDesktopView() {
  const view = Cookies.get('desktopSearchResultsView');
  return (view === 'list' || view === 'split') ? view : 'split';
}

function initialMapBounds() {
  if (!window.google) {
    // Google isnt loaded.  Nothing on the page has a map bounds.
    // Don't crash.  Just return null.
    return null;
  }
  let mapPositionStorage = new MapPositionStorage(mapSupport.mapScopeKey);
  let mapPosition = mapPositionStorage.get();

  if(mapPosition && mapPosition.bounds){
    var location = mapPosition.bounds;
    if (window.google)
    return mapConverters.sparkShapeToGoogleBounds(location);
  } else {
    return null;
  }
}

export function setState(key, newValue, options) {
  if(typeof options !== 'object') {
    options = {}
  }
  const triggerEvent = typeof options.triggerEvent === 'boolean' ? options.triggerEvent : true;

  _initializeStore();
  const oldValue = getState(key);
  store[key] = newValue;
  
  if(triggerEvent && valueHasChanged(key, oldValue, newValue)){
    const eventName = 'stateChange:' + key;
    const eventData = {
      detail: {
        oldValue: oldValue,
        newValue: newValue
      }
    };

    const changeEvent = new CustomEvent(eventName, eventData);
    log(`${key}: ${oldValue} => ${newValue}`)
    window.dispatchEvent(changeEvent);

    
    if(key === 'filter'){
      if(!window.searchResultsData){
        window.searchResultsData = {};
      }
      
      window.searchResultsData.filter = store['filter'].toString();
      window.searchResultsData.shapes = store['filter'].shapes();
  
      /**
       * Only update the Android Filter Screen if the change to the filter didn't come from the Android app
       */
      if(nativeAppInterface.isReactNativeFlexPanelSearchResultsEnabled() && !options.isFromNativeAppFilterScreen){
        debouncedUpdateNativeAppFilterScreen();
      }
    }
    
  } else {
    log(`${key} was set but the value has not changed: ${oldValue}`)
  }

}

export function getState(key) {
  _initializeStore();

  if(Object.keys(store).indexOf(key) < 0) {
    throw new Error(`Uninitialized key: ${key}`);
  }

  if(typeof valueDuplicators[key] === 'function') {
    return valueDuplicators[key](store[key]);
  } else {
    return store[key];
  }
}


function valueHasChanged(key, oldValue, newValue) {
  
  if(typeof oldValue === 'undefined' && typeof newValue === 'undefined') {
    return false;
  }
  if(oldValue === null && newValue === null) {
    return false;
  }
  if(typeof oldValue === 'undefined' || typeof newValue === 'undefined') {
    return true;
  }
  if(oldValue === null || newValue === null) {
    return true;
  }
  if(typeof changeDetectors[key] === 'function') {
    return changeDetectors[key](oldValue, newValue);
  } else {
    return oldValue !== newValue;
  }

}

function log(message) {
  if(debug){
    if(typeof message === 'object'){
      console.log('[state]', message);
    } else {
      console.log('[state] ' + message);
    }
  }
}

let changeDetectors = {
  filter: (oldValue, newValue) => {
    return oldValue.toString() !== newValue.toString()
  },
  annotations: (oldValue, newValue) => {
    return JSON.stringify(oldValue) != JSON.stringify(newValue);
  },
  mapBounds: (oldValue, newValue) => {
    return oldValue.toString() !== newValue.toString()
  }
}

let valueDuplicators = {
  filter: (oldValue) => {
    return new Filter(clonedeep(oldValue.expressions()), oldValue._locationFields)
  }
}

const debouncedUpdateNativeAppFilterScreen = debounce(function(){
  if(window.searchResultsData && nativeAppInterface.isReactNativeFlexPanelSearchResultsEnabled()){
    const { quickSearchId, savedSearchId, comparableSearchId } = window.searchResultsData;
    const filter = getState('filter');

    nativeAppInterface.displayFilterScreen({ filterString: filter.toString(), quickSearchId, savedSearchId, comparableSearchId, shapes: filter.shapes(), updateOnly: true });
  }
}, 200);

