import { Controller } from "stimulus";
import { showLdp } from "../../helpers/ldp_navigation_helpers"
import {getState, setState} from '../../search_results/state'
import { mapListConnection } from '../../search_results/map_list_connection'
import { translations } from '../../translations.js.erb'
import { FlexmlsMap } from '../../map/flexmls_map'
import { markerManager } from '../../map/marker_manager'
import Filter from "../../filter";
import GlobalOverlayManager from "../../global_overlay_manager"

export default class extends Controller {

  map = null
  mapListConnection = null
  allowOverlayFiltering = ($('body').attr('mweb-map-overlay-filtering') === 'true');

  async connect(evt) {
    this.load_google()
    
    if (this.map === null) {
      var defaultOptions = {
        contactId: window.mapSupport.contactId,
        currencySymbol: window.mapSupport.currencySymbol,      
        mlsDefaultBounds: window.mapSupport.mlsDefaultBounds,
        translations: translations.map,
        loadingMessage: {
// These are causing problems when the map is not visible at startup
// When the summary view is the staring view, the map puts up the spinner
// until the clusters are finished loading.  On smaller phones, the map
// is loaded but not visible.  But the clusters wont finish loading until
// the map has become a visible part of the page.  For now, take this
// out.  We already have a "Loading..." msg when the turbo-frame is loading.
// If we need an indicator to show that clusters are in the process of
// loading, we can put it in at the turbo-frame level instead of the main
// page level (where the UIL is in charge of displaying it.)
//          start: showPageLoadingMsg,
//          stop: hidePageLoadingMsg
        },
        drawingCompleteCallback: this.drawingCompleteCallback.bind(this)
      };
      var mapOptions = $.extend({}, defaultOptions, {});
      this.map = new FlexmlsMap(document.getElementById('map'), mapOptions);

      this.map.init();
      $(document).off('click', '#overlayKeyBtn, .overlaysPopover, .moreMapToolsBtn, .shapesPopover');

      if (this.allowOverlayFiltering) {
        // This is a fresh map.  If there are overlays/shapes in the filter, we should draw them now.
        this.addFilterOverlayShapesToMap(getState('filter'))
        $(document).on('mouseenter', '#contains-MapOverlayShape li.select2-selection__choice', this.highlightOverlayShape.bind(this));
        $(document).on('mouseleave', '#contains-MapOverlayShape li.select2-selection__choice', this.unhighlightOverlayShape.bind(this));
        $(document).on('mouseenter', '#contains-drawings li.select2-selection__choice', this.highlightDrawnShape.bind(this));
        $(document).on('mouseleave', '#contains-drawings li.select2-selection__choice', this.unhighlightDrawnShape.bind(this));
      }
    }
    if (this.allowOverlayFiltering) {
      // Check for the openDrawingTools param.
      const turboFrame = this.element.closest('turbo-frame');
      if (turboFrame) {
        const frameSrc = turboFrame.getAttribute("src");
        if (frameSrc) {
          // Parse the URL using the URL API
          const url = new URL(frameSrc, window.location.origin); // Ensure absolute URL

          // Use URLSearchParams to check for the 'openDrawingTools' parameter
          const params = url.searchParams;
          if (params.get('openDrawingTools') === 'true') {
            document.getElementById('drawOnMapBtn').click()
          }
        }
      }
    }
  }

  disconnect() {
    this.map.resetShapesMenu();
    this.map.onMapUnload();
    if (this.allowOverlayFiltering) {
      GlobalOverlayManager.clearAllFilteredShapes();
      $(document).off('mouseenter', '#contains-drawings li.select2-selection__choice');
      $(document).off('mouseleave', '#contains-drawings li.select2-selection__choice');
      $(document).off('mouseenter', '#contains-MapOverlayShape li.select2-selection__choice');
      $(document).off('mouseleave', '#contains-MapOverlayShape li.select2-selection__choice');
    }
  }


  clearTempMarkers(evt) {
    this.map.deleteTemporaryMarkersAndStuff();
  }

  drawShapes(evt) {
    // Something outside the maps controller asked us to draw shapes
    $('.shapesPopover').trigger('click')
  }

  async filterChangedCallback(newExpressions, oldExpressions) {

    let newFilter = new Filter(newExpressions, mapSupport.locationFields);
   
    var added   = new Filter(Filter.expressionsAdded(oldExpressions, newExpressions), mapSupport.locationFields);
    var removed = new Filter(Filter.expressionsRemoved(oldExpressions, newExpressions), mapSupport.locationFields);
  
    var deleteAnnotations = true;
    newExpressions.forEach( function(expression) {
      if (expression.type === 'shape' || expression.field === 'MapOverlayShape') {
        deleteAnnotations = false;
      }
    });

    if (deleteAnnotations) {
      setState('annotations',[]);
      sessionStorage.removeItem('annotations')
    }
  
    if(getState('mapBounds') === null){
      setState('filter', newFilter);
  
    } else if(typeof this.map === 'undefined'){
      setState('filter', newFilter);

      if(added.locations().length > 0 || removed.locations().length > 0) {
        setState('mapBounds', null);
      }
  
    } else {
      if (this.allowOverlayFiltering) {
        let shapesAddedOrRemoved = false
        if (added.shapes().length > 0 || removed.shapes().length > 0) {
          this.map.refreshMapShapes()
          shapesAddedOrRemoved = true
        }

        if (added.locations().length > 0 || removed.locations().length > 0) {
          await this.addFilterOverlayShapesToMap(added)
          this.removeUnfilteredShapesFromMap(removed)
          shapesAddedOrRemoved = true
        }

        if (shapesAddedOrRemoved) {
          this.map.freeze()
          let shapesAdded = (added.locations().length > 0 || added.shapes().length > 0)
          let shapesRemoved = (removed.locations().length > 0 || removed.shapes().length > 0)
          if (shapesRemoved && newFilter.locations().length === 0 && newFilter.shapes().length === 0) {
            this.map.moveMapToMlsDefaultBounds().then((bounds) => {
              setState('mapBounds', bounds);
              this.map.unfreeze();
              this.map.updateMap();
            })
          } else {
            var self = this
            let bounds1 = this.map.getDrawnShapesBounds();
            let bounds2 = GlobalOverlayManager.getTotalMapOverlayShapeBounds();
            if (bounds1 === null) {
              // They cant both be null or we wouldnt be here
              bounds1 = bounds2
            } else if (bounds2 !== null) {
              // If both are NOT null, combine them.
              bounds1.union(bounds2)
            }

            this.map.updateMap({bounds: bounds1}).done(function() {
              self.map.moveMapToBoundary(bounds1).then((bounds) => {
                setState('mapBounds', bounds);
                self.map.unfreeze();
              })
              .catch((e) => {
                console.log(e.message)
              });
            });
          }
        }
        this.map.unfreeze()
        return;

      } else if (added.locations().length === 0 && removed.locations().length === 0) {
        setState('filter', newFilter);
      } else {
        this.map.freeze();
  
        setState('filter', newFilter) 
  
        if (removed.locations().length > 0 && newFilter.locations() == 0 ) {
          this.map.moveMapToMlsDefaultBounds().then((bounds) => {
            setState('mapBounds', bounds);
  
            this.map.unfreeze();
  
            this.map.updateMap();
          });
        } 
        else {
          var self = this
          this.map.updateMap({bounds: null}).done(function() {
            self.map.moveMapToListingDataBoundary().then((bounds) => {
              setState('mapBounds', bounds);
              self.map.unfreeze();
            })
            .catch((e) => {
              console.log(e.message)
            });
          });
        }
        this.map.unfreeze()
      }
      this.map.refreshMapShapes();
    }
  }

  filterChanged(data) {
    // Both flexmls map and this controller handle filter changes.
    // We are refreshing the map shapes here so we do not influence the 'live' route.
    // This use to get called at the end of a filterChangedCallback.
    // TODO: When we go live with the new route, we should revisit this and possibly move this into flexmls_map's filterChanged function. Or move that function here.
    this.filterChangedCallback(data.detail.newValue._data, data.detail.oldValue._data);
  }

  drawingCompleteCallback(filter, newBounds, annotation) {
    this.map.freeze();
  
      // Make a copy of annotations so the state's actual annotations value
      // doesn't change until we call setState.
      var annotations = JSON.parse(JSON.stringify(getState('annotations')));
  
    if (annotation) {
      annotations.push(annotation);
      setState('annotations', annotations);
      if (this.allowOverlayFiltering) {
        sessionStorage.setItem('annotations',JSON.stringify(annotations))
      }
    }
  
    setState('filter', filter);
  
    this.map.moveMapToBoundary(newBounds).then((bounds) => {
  
      // Since the map is frozen, the bounds need to be updated manually.
      setState('mapBounds', bounds);
  
      this.map.unfreeze();
  
      this.map.updateMap().done(() => {
        setState('listingCountTotal', this.map.mappedListingCount());
      });
    });
  }

  mapZoomIn(evt){
    this.map.googleMap.setZoom(this.map.googleMap.getZoom() + 1);
  }

  mapZoomOut(evt){
    this.map.googleMap.setZoom(this.map.googleMap.getZoom() - 1);
  }

  showListingCard(evt) {
    if (evt.detail === null) {
      markerManager.deletePopupMarkers();
    } else {
      markerManager.addPreviewMarker(evt.detail);
    }
  }

  showLdpFromMap(evt) {
    this.map.resetShapesMenu()
    var target = $(evt.target)

    if (
        (target.hasClass('marker-preview-wrapper-above') || target.hasClass('marker-preview-wrapper-below') ) ||
        (target.closest('.rsArrowLeft').length) ||
        (target.closest('.rsArrowRight').length)||
        (target.closest('.saveListingLink').length) ) {
      // let addToCollection deal with it
    } else {
      evt.preventDefault()
      // Prevent multiple fast clicks from attempting more than one load of the LDP
      if ($('#priorityOverlay').hasClass('loading')) return
      $('#priorityOverlay').addClass('loading')

      // The url for the listing detail page is stored in a data attribute on
      // the preview. If the preview is clicked, we need to look up the dom to
      // find it, but if the outermost wrapper was clicked, we need to look
      // inside the clicked element.
      var urlHolder = $(evt.target).closest('[data-listing-show-url]')

      if(urlHolder.length < 1) {
        urlHolder = $(evt.target).find('[data-listing-show-url]')
      }

      var requestUrl = new URL(urlHolder.attr('data-listing-show-url'))

      // TODO: Fix this inside the preview card
      if (requestUrl.searchParams.has('listing_id')) requestUrl.searchParams.delete('listing_id')
      if (requestUrl.searchParams.has('view')) requestUrl.searchParams.delete('view')

      var listingUrl = requestUrl.href
      let browserUrl = listingUrl

      // TODO: Add the listing id as an attribute somewhere in the preview card
      let pattern = /\/listings\/(\d{26})/
      let match_result = listingUrl.match(pattern)
      let listingId = match_result[1]

      var mapItem = markerManager.getMarkerInfoById(listingId)
      listingUrl = listingUrl.replace("/listings/","/listing_detail/");
      if (mapItem !== null) {
        mapItem.listingUrl = listingUrl
        mapItem.pushState = true
        mapItem.browserUrl = browserUrl
        showLdp(mapItem)
      } else {
        console.log("No marker information found. This should not happen.")
      }
    }
  }

  requestPreviousListing(evt) {
    var currentListingId = evt.detail
    var previousMarkerMapItem = markerManager.getPreviousMarkerInfo(currentListingId)

    if (previousMarkerMapItem === null) {
      // We should not get here because the previous button should have been disabled if
      // there were no more.  But just in case.
      console.log("No previous map markers found");
    }

    // Someone hit the prev button on an LDP.  Replace the current LDP with the new LDP
    var listingUrl = window.location.href;
    listingUrl = listingUrl.replace(currentListingId, previousMarkerMapItem.listingId);

    // Update the URL in the browser
    history.replaceState('','',listingUrl)
    // Adjust the URL to get the details frame
    listingUrl = listingUrl.replace("/listings/","/listing_detail/");

    previousMarkerMapItem.listingUrl = listingUrl
    previousMarkerMapItem.pushState = false;
    showLdp(previousMarkerMapItem);
  }

  requestNextListing(evt) {
    var currentListingId = evt.detail
    var nextMarkerMapItem = markerManager.getNextMarkerInfo(currentListingId)

    if (nextMarkerMapItem === null) {
      // We should not get here because the next button should have been disabled if
      // there were no more.  But just in case.
      console.log("No more map markers found");
    }

    // Someone hit the next button on an LDP.  Replace the current LDP with the new LDP
    var listingUrl = window.location.href;
    listingUrl = listingUrl.replace(currentListingId, nextMarkerMapItem.listingId);

    // Update the URL in the browser
    history.replaceState('','',listingUrl)

    // Adjust the URL to get the details frame
    listingUrl = listingUrl.replace("/listings/","/listing_detail/");

    nextMarkerMapItem.listingUrl = listingUrl
    nextMarkerMapItem.pushState = false;
    showLdp(nextMarkerMapItem);
  }

  refreshMapFromList(evt) {
    // The data action for this should only be defined if we are a newsfeed or notifications
    // It will update the map with the newly loaded items in the table/summary results.
    this.map.updateMap();
  }

  requestListingIds(event) {
    let listingIds = markerManager.getListingIds();
    const response = new CustomEvent(event.detail.returnEvent, {detail: { listingIds: listingIds }})
    window.dispatchEvent(response)
  }

  async addFilterOverlayShapesToMap(filter) {
    // This needs to be async so that we dont leave here until
    // all of the items have been added to the map.  Calls that
    // happen after this use the map shapes to define the map bounds.

    const promises = [];

    // Process MapOverlayShape entries
    const shapePromises = filter.locations()
        .filter(obj => obj.field === 'MapOverlayShape')
        .map(expression => (async () => {
            const shape = await GlobalOverlayManager.getMyMapOverlayShape(
                expression.condition.replaceAll("'", "")
            );
            GlobalOverlayManager.addFilterOverlayShapeToMap(shape.Id, shape);
        })());
    promises.push(...shapePromises);

    // Wait for all operations to complete
    await Promise.all(promises);
  }

  removeAllShapes() {
    GlobalOverlayManager.clearAllFilteredShapes();
    this.map.deleteAllDrawings();
    setState('annotations',[])
    sessionStorage.removeItem('annotations')
  }

  removeUnfilteredShapesFromMap(filter) {
    // Make a copy of annotations so the state's actual annotations value
    // doesn't change until we call setState.
    let annotations = JSON.parse(JSON.stringify(getState('annotations')));
    filter.locations().filter(obj => obj['field'] === 'MapOverlayShape').forEach(expression => {
      GlobalOverlayManager.removeFilterOverlayShapeFromMap(expression['condition'].replaceAll("'",""))
      annotations = annotations.filter(a => a.Match.Substring !== expression['condition']);
    });
    setState('annotations',annotations)
    sessionStorage.setItem('annotations',JSON.stringify(annotations))
  }

  highlightOverlayShape(event) {
    let overlayShapes = $(event.currentTarget).find('[data-shape]')
    if (overlayShapes.length > 0) {
      GlobalOverlayManager.fillOverlayShape($(overlayShapes[0]).attr('data-shape').replaceAll("'",""), 0.9)
    }
  }

  unhighlightOverlayShape(event) {
    let overlayShapes = $(event.currentTarget).find('[data-shape]')
    if (overlayShapes.length > 0) {
      GlobalOverlayManager.fillOverlayShape($(overlayShapes[0]).attr('data-shape').replaceAll("'",""), 0.15)
    }
  }

  highlightDrawnShape(event) {
    let shapes = $(event.currentTarget).find('[data-shape]')
    if (shapes.length > 0) {
      this.map.highlightDrawnShape($(shapes[0]).attr('data-shape'))
    }
  }

  unhighlightDrawnShape(event) {
    let shapes = $(event.currentTarget).find('[data-shape]')
    if (shapes.length > 0) {
      this.map.unhighlightDrawnShape($(shapes[0]).attr('data-shape'))
    }
  }

  load_google() {
    // We only want to load the google script once.  With turboframes,
    // we could end up loading this multiple times without this check.
    if (!window.google) {
      const script = document.createElement('script');
      
      // Default key
      let keystring = "client=gme-fbsdata";
      
      // SCC key
      if (Flexmls.currentUser.mls_id == "20220727192737510867000000") {
        keystring = "key=AIzaSyBXiwAVI0znxgnvMQ7CRT4zGYP2UFd2KpM";
      }
      
      script.src = 'https://maps.googleapis.com/maps/api/js?v=3&' + keystring + '&libraries=drawing';
      script.async = false;
      script.defer = true;

      script.onload = () => {
      // You can initialize your Google Maps functionality here
      };

      document.body.appendChild(script);
    } else {
    }
  }
}
