/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import routes from 'src/routes'
window.routes = routes;

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()

const context = require.context("../src/controllers", true, /\.js$/)
window.Stimulus.load(definitionsFromContext(context))

import {searchFormFlex} from 'src/listings/search_form_flex.js';
window.searchFormFlex = searchFormFlex;

document.addEventListener('searchFormFlex:reloadData', ({detail: { serializedForm = null, isBasicsChange = false, dispatchTheEvent = true }}) => {
  if(serializedForm){
    searchFormFlex.reloadData(serializedForm, isBasicsChange, dispatchTheEvent);
  }
});

/**
 * Show the filter button when the Android App's Filter Panel Closes
 */
document.addEventListener('nativeApp:closeFilterPanel', () => {
  document.querySelector('.sub-nav__toggle--left')?.classList?.remove('active');
});


/**
 * Hide the filter button when the Android App's Filter Panel opens
 */
document.addEventListener('nativeApp:openFilterPanel', () => {
  document.querySelector('.sub-nav__toggle--left')?.classList?.add('active');
});


document.addEventListener('nativeApp:closeLDP', () => {
  $('.ldp-overlay').trigger('click');
});



