//
//
//
// This depends on jquery.royalslider.min.js
//
//
//

import { nativeAppInterface } from './native_app_interface';

var widthArray = [640, 800, 1024, 1280, 1600, 2048];
var heightArray = [480, 600, 768, 1024, 1200, 1600];

var DEFAULT_CONTAINER_PADDING = 16;
var DEFAULT_NAV_BAR_HEIGHT = 48;

var slider;
var isFlagship;
var js_listing;
var most_recent_listing_id;
var DEFAULT_THUMB_HEIGHT = 90;
var WINDOWS_SCROLLBAR_HEIGHT = 18;
var MAC_SCROLLBAR_HEIGHT = 10;
var THUMBNAIL_LENGTH = 120;
var thumbnailHeightWithScrollbar;
var xhr; // in-progress request
var bestWidth;
var bestHeight;
var categories;
var parsedTaggedListingMedia;
var lastActiveSlide;
var allSlides;

function init(opts) {
  isFlagship = opts.isFlagship || false;
  var bind_events = opts.bindEvents;
  var sliderHeight = opts.height || 5;
  var scaleMode = isFlagship ? 'none' : 'fill';
  var imageAlignCenter = !isFlagship;
  var setWidth = isFlagship ? window.innerWidth : undefined;
  var setHeight = isFlagship ? window.innerHeight / 2 : undefined;
  var jumpToPhoto = opts.jump_to_photo;

  categories = opts.categories
  parsedTaggedListingMedia = opts.parsedTaggedListingMedia
  js_listing = opts.js_listing || {};

  if ($('.royalSlider').length === 0) {
    // early exit if there's nothing to do
    return;
  }

  $('.royalSlider').royalSlider({
    arrowsNav: true,
    arrowsNavAutoHide: true,
    autoScaleSlider: !isFlagship, // When flagship, we don't want to autoscale so that this fits nicely in the flagship iframe
    autoScaleSliderHeight: sliderHeight,
    autoScaleSliderWidth: 8,
    controlNavigation: 'thumbnails',
    fadeinLoadedSlide: false,
    usePreloader: false,
    fullscreen: {
      buttonFS: true,
      enabled: true,
      nativeFS: false
    },
    globalCaption: true,
    imageScaleMode: scaleMode,
    imageAlignCenter: imageAlignCenter,
    keyboardNavEnabled: true,
    loopRewind: true,
    navigateByClick: false,
    numImagesToPreload: 2,
    // imgWidth and imgHeight are set only to allow the loading of
    // the image to be visible. These do not seem to impact the
    // displayed height/width of the image with the imageScaleMode
    // settings we use.
    imgWidth: setWidth,
    imgHeight: setHeight,
    thumbs: {
      arrows: true,
      arrowsAutoHide: true,
      firstMargin: false,
      fitInViewport: false,
      spacing: 8
    },
    transitionType: 'fade',
    transitionSpeed: 200,
    video: {
      autoHideArrows: false,
      youTubeCode: getYoutubeEmbed(),
      vimeoCode: getVimeoEmbed()
    }
  });

  slider = $('.royalSlider').data('royalSlider');
  if (jumpToPhoto > 0) {
    // jump to the photo number indicated by {integer}, a 0-indexed array of photos
    // need to figure out the number of videos, virtual tours, and floor plan images as those
    // sit between the primary photo (0) and the second photo (1)
    var mediaOffset = js_listing.displayed_video_count + js_listing.displayed_vtour_count + js_listing.displayed_floorplan_count;
    slider.goTo(jumpToPhoto + mediaOffset)
  }

  $("#closeGalleryButton").on("click", () => {
    slider.exitFullscreen();
  })

  $('.rsGCaption').css('display', 'block');

  // Based on http://jsfiddle.net/DmitrySemenov/p2h7dsho/
  // Not sure why this isn't included as part of how
  // RoyalSlider works, but who knows
  slider.ev.on('rsAfterContentSet', function (e, slideObject) {
    setupCaptions();
    updateSlideCount();

    /*
        If the android app supports it, 
            directly open the video in the Android App's Fullscreen Viewer and prevent the video from playing inline
    */
    if(nativeAppInterface.isReactNativeMediaCategoriesEnabled()){
      /**
       * For some reason `.reactNativePlayVideoVirtualTourOnClick` isn't mounted when `rsAfterContentSet` gets called when there is only one slide
       * Wrapping the callback in a setTimeout pushes this call to the end of the queue
       * https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful
       */
      setTimeout(function() {
        $("a.reactNativePlayVideoVirtualTourOnClick").removeAttr('href');
      
        $('.reactNativePlayVideoVirtualTourOnClick').each(function() {
          $(this).on('click', function() {
            var reactNativeVideo = $(this).data('react-native-video');
            var reactNativeURI = $(this).data('react-native-uri');
  
            nativeAppInterface.enterFullScreenVideoVirtualTour({
              video: reactNativeVideo,
              uri: reactNativeURI
            });
          });
        });
  
        $('.reactNativePointerEventBlocker').css('pointer-events', 'none');  
      }, 0);
    }
  });

  if (slider.numSlides > 1) {
    $('.royalSlider').append('<div class="slideShowCount rs-slide-count"></div>');
  }

  if (!$.flexmlsUi.isTouch() && !isFlagship) {
    displayFullscreenResizeBtn()
  }

  $('.royalSlider .rsOverflow').find('.rsFullscreenBtn').removeClass('rsHidden').css('display', 'none');

  if(isFlagship){
    setUpScrollingForThumbnails();

    // preserve the exact height and width of the iframe in regular screen mode
    // so that it can properly resize when exiting from full screen
    if ($.flexmlsUi.isTouch()) {
      $(slider).data('innerHeight', $(window).innerHeight());
      $(slider).data('innerWidth', $(window).innerWidth());
    }

    $('.rsOverflow').addClass('inherit-dimensions');
  }

  bindSliderEvents();

  if (bind_events && isFlagship) {
    bindWindowEvents();
  }

  // Unleash the hounds!
  //
  // For flagship, trigger loading of delayed background-image thumbnails. For
  // not-flagship, wait for the user to interact with the slideshow before
  // loading all of the other images.
  if (isFlagship) {
    loadDelayedImages();
  } else {
    slider.ev.one('rsBeforeMove rsDragStart', handleFirstInteraction);
  }
}

function handleFirstInteraction() {
  loadDelayedImages();
  slider.ev.unbind('rsBeforeMove rsDragStart', handleFirstInteraction);
}

function loadDelayedImages() {
  $('.rsTmb.photo.delayed').removeClass('delayed');
}

function getYoutubeEmbed() {
  var youtubeEmbed =
    '<iframe src="https://www.youtube.com/embed/%id%?&autoplay=1&mute=1&rel=1&showinfo=0';
  youtubeEmbed += isFlagship ? '&fs=0" height="95%" width="95%" ' : '" allowfullscreen ';
  youtubeEmbed += 'allow="autoplay" frameborder="no"></iframe>';
  return youtubeEmbed;
}

function getVimeoEmbed() {
  var vimeoEmbed =
    '<iframe src="https://player.vimeo.com/video/%id%?%h%byline=0&portrait=0&autoplay=1&muted=1"';
  vimeoEmbed += isFlagship ? ' height="95%" width="95%" ' : ' allowfullscreen ';
  vimeoEmbed += 'frameborder="no" allow="autoplay"></iframe>';
  return vimeoEmbed;
}

function shouldFullscreenButtonShow() {
  var url = location.href;
  var isPreview = /preview/.test(url);
  return !isPreview;
}

function bindWindowEvents() {
  setupDynamicResizing();
  window.addEventListener(
    'message',
    function (event) {
      processMessageFromFlagship(event);
    },
    false
  );
  window.addEventListener(
    'keydown',
    function (event) {
      processKeypress(event);
    },
    false
  );
}

function processMessageFromFlagship(event) {
  if (event.origin.indexOf("fbsdata.com") === -1 && event.origin.indexOf("flexmls.com") === -1 && event.origin.indexOf("flexmls.cloud") === -1){
    console.error("Found event from "+event.origin+" instead of allowed domain");
    return;
  }
  var action = event.data;
  if (action == 'exitFullscreenFromFlagship') {
    handleFlagshipFullscreenChange();
  } else if (action == 'next slide') {
    triggerScrollForward();
    slider.next();
  } else if (action == 'prev slide') {
    triggerScrollBackward();
    slider.prev();
  } else {
    /* updateMediaURL */
    var listing_id = null;
    var mod_timestamp = null;
    var param_string = null;

    try {
      /* the arguments used to come into the function this way,
       * and may for some time because this gallery code has to go out before the
       * corresponding search results code in flagship can be changed.
       */
      if (/^updateMediaURL:/.test(action)) {
        // this action message will be either:
        //  - updateMediaURL:<listing_id>:<some parameters about what media types to show>
        //  - updateMediaURL:<listing_id>:<mod_timestamp>:<some parameters about what media types to show>
        var split_action = action.split(':');
        listing_id = split_action[1];
        var show_which_media_params = '';
        if (split_action.length === 3) {
          mod_timestamp = split_action[2];
        }
        for (var x = 0; x < split_action.length; x++) {
          if (/show_/.test(split_action[x])) {
            param_string = split_action[x];
          }
        }
      } else {
        /* Once flagship is updated, we'll receive an object with the params we want */
        
        // CUR-28348 - Account for non-FBS messages
        // Ex: McAfee WebAdvisor uses postMessage with params we don't want
        if (action.selected_listing) {
          listing_id = action.selected_listing;
          mod_timestamp = action.mod_timestamp;
          param_string = action.params;
        }
      }
    } catch (e) {
      console.warn('Exception updating gallery', e);
    }

    if (listing_id !== null) {
      updateListingMediaUrl(listing_id, mod_timestamp, param_string);
    }
  }
}

function processKeypress(e) {
  var keyPressed = e.keyCode;
  if (keyPressed == 37) {
    // backwards arrow
    triggerScrollBackward();
  } else if (keyPressed == 38) {
    // up arrow
    // send message to flagship so the previous listing loads
    window.parent.postMessage('keyCheck:38', '*');
  } else if (keyPressed == 39) {
    // forwards arrow
    triggerScrollForward();
  } else if (keyPressed == 40) {
    // down arrow
    // send message to flagship so the next listing loads
    window.parent.postMessage('keyCheck:40', '*');
  }
}

function triggerScrollForward() {
  if (slider.currSlideId == slider.numSlides - 1) {
    // if on the last slide, loop back to the first
    loopToFirstSlide();
  } else {
    triggerScroll('forward');
  }
}

function triggerScrollBackward() {
  if (slider.currSlideId == 0) {
    // if on the first slide, loop back to the last
    loopToLastSlide();
  } else {
    triggerScroll('backward');
  }
}

function loopToFirstSlide() {
  $('.rsNav').scrollLeft(0);
}

function loopToLastSlide() {
  var lastThumbnailPosition = $('.rsThumbsContainer')[0].clientWidth;
  $('.rsNav').scrollLeft(lastThumbnailPosition);
}

function triggerScroll(direction) {
  var scrolledDistance = $('.rsNav').scrollLeft();
  // each thumbnail is always 112 px in width
  // add or subtract 112 the amount of pixels that have been scrolled
  if (direction == 'forward') {
    $('.rsNav').scrollLeft(scrolledDistance + THUMBNAIL_LENGTH);
  } else if (direction == 'backward') {
    $('.rsNav').scrollLeft(scrolledDistance - THUMBNAIL_LENGTH);
  }
}

function setUpScrollingForThumbnails() {
  var $rsNav = $('.rsNav')[0];
  // determine if a scrollbar is showing all the time on the thumbnail strip or if its just showing on hover/scroll.
  // Read https://davidwalsh.name/detect-scrollbar-width for an explanation.
  // I found that sometimes there is a difference of 1 even when there is no scrollbar present, so I am setting the
  // difference threshold in pixels to be 2 and above
  var scrollbarHeight = $rsNav.offsetHeight - $rsNav.clientHeight;
  var isScrollbarShowing = scrollbarHeight >= 2;
  // if the scrollbar is always present, give thumbnails more height
  if (isScrollbarShowing) {
    var os = getOS(); // the Windows scrollbar is thicker than the one in Mac OS
    if (os == 'Mac OS') {
      thumbnailHeightWithScrollbar = DEFAULT_THUMB_HEIGHT + MAC_SCROLLBAR_HEIGHT;
    } else if (os == 'Windows') {
      thumbnailHeightWithScrollbar = DEFAULT_THUMB_HEIGHT + WINDOWS_SCROLLBAR_HEIGHT;
    } else {
      thumbnailHeightWithScrollbar = DEFAULT_THUMB_HEIGHT;
    }
  }

  resizeSlider();
}

function bindSliderEvents() {
  slider.slides[0].holder.on('rsAfterContentSet', function () {
    $(window).trigger('resize');
    $('.rsThumbsContainer').show();
  });

  slider.ev.on('rsSlideClick', function (event, originalEvent) {
    var currSlide = $(slider.currSlide.content);
    var index = currSlide.data('index');

    
    if(nativeAppInterface.isReactNativeMediaCategoriesEnabled()){
      /**
       * For the Android app, only open the React Native Image Gallery if its not a video
       * Videos should play in fullscreen when tapped instead
       */
      if(!currSlide.hasClass('unembeddable') && !currSlide.hasClass('vimeo-supported') && !currSlide.hasClass('video') && !currSlide.hasClass('vid') && (!currSlide.hasClass('reactNativePlayVideoVirtualTourOnClick') && !currSlide.hasClass('tour'))){
        slider.enterFullscreen();
      }
      return;
    }

    // unembeddable videos need no action
    if (currSlide.hasClass('unembeddable')) {
      return;
    }
    // for supported vimeo videos we trigger the video to play in the background
    else if (currSlide.hasClass('vimeo-supported')) {
      currSlide.find('.rsPlayBtn').click();
    }
    // unsupported videos require loading them in their iframe, or displaying an error message
    else if (currSlide.hasClass('video') && js_listing.videos[index]) {
      let htmlString = "";
      
      if (js_listing.videos[index]?.attributes?.ObjectHtml) {
        htmlString = js_listing.videos[index].attributes.ObjectHtml;
      }  else {
        htmlString = js_listing.videos[index];
      }
      $('#videoModal').find('.videoContent').html(htmlString);
      var $videoModal = $('#videoModal').find('.videoContent')

      if (!$videoModal[0] || $videoModal[0].firstChild != $videoModal[0].lastChild) {
        $('#errorModal').modal('show');
      }
      else{
        var wasFullscreen = slider.isFullscreen;
        
        // MS Edge shows Fullscreen on top of the video screen, so exit
        // fullscreen when playing video from it.
        slider.exitFullscreen();

        // If the image gallery was fullscreen, tell the Android app to stay in fullscreen
        if(wasFullscreen){
          nativeAppInterface.photoGalleryDidDisplay();
        }

        $('#videoModal').modal('show').on('hidden.bs.modal', function () {
          $videoModal.children().remove();

          // Tell the Android app to exit fullscreen when we close the video
          nativeAppInterface.photoGalleryDidClose();
        });
        
        return;
      }
    }

    slider.enterFullscreen();
  });

  slider.ev.on('rsEnterFullscreen', function() {
    allSlides = slider.slides

    // This is to be used to communicate to Android native
    // Tell Android that user has tapped on the full screen
    // Exit fullscreen so Android can display its own native fullscreen gallery.
    if(nativeAppInterface.isNative()){
      let currentMediaCategory = 'Photos';
      const currentSlideJQuery = $(slider.currSlide.content[0]);
  
      if( currentSlideJQuery.hasClass('floorplan')){
        currentMediaCategory = 'Floor Plans'
      } else if (currentSlideJQuery.hasClass('vid')){
        currentMediaCategory = 'Videos'
      } else if (currentSlideJQuery.hasClass('tour')){
        currentMediaCategory = 'Virtual Tours'
      }

      nativeAppInterface.enterGalleryFullScreen({
        currentIndex: slider.currSlideId,
        currentMediaCategory: currentMediaCategory,
        numberOfImages: slider.numSlides,
        callback: () => { slider.exitFullscreen(); }
      });
      nativeAppInterface.photoGalleryDidDisplay();
      return;
    }

    if(isFlagship){
      $('.rsOverflow').removeClass('inherit-dimensions');
      window.parent.postMessage('triggerFullscreen', '*');
    }

    // Fixes a problem with Safari fullscreen which limits the fullscreen view to
    // only the .listing-detail-content-column.
    $('.listingDetailContentColumn').css('position', 'static');
    slider.st.imageScaleMode = 'fit-if-smaller';
    $('.rsCaption .text').css('max-height', '100%');
    slider.updateSliderSize(true);

    displayFullscreenResizeBtn()

    lastActiveSlide = slider.currSlide
    
    // Show the close button
    showCloseGalleryButton();
  });

  slider.ev.on('rsExitFullscreen', function () {
    if (isFlagship) {
      $('.rsOverflow').addClass('inherit-dimensions');
      window.parent.postMessage('cancelFullscreen', '*');
      resizeSlider();
    }
    // Fixes a problem with Safari fullscreen which limits the fullscreen view to
    // only the .listing-detail-content-column.
    $('.listingDetailContentColumn').css('position', '');
    slider.st.imageScaleMode = 'fill';
    slider.updateSliderSize(true);
    $('.rsVideoContainer').removeAttr('style');
    setupCaptions();

    nativeAppInterface.photoGalleryDidClose();

    $('#All').trigger('click')
    $('.royalSlider .rsOverflow').find('.rsFullscreenBtn').removeClass('rsHidden').css('display', 'none');

    $('.rsTmb:first-child').first().removeClass('delayed')

    // Define variables for use in retainLastActiveSlide()
    allSlides = slider.slides;
    lastActiveSlide = slider.currSlide;
    
    retainLastActiveSlide(lastActiveSlide)
    slider.ev.one('rsBeforeMove rsDragStart', handleFirstInteraction);
    
    // Hide the close button
    hideCloseGalleryButton();
  });

  slider.slides[0].holder.on('rsAfterContentSet', function () {
    updateSlideCount();
    window.parent.postMessage('imageGalleryLoaded', '*');
  });

  slider.ev.on('rsBeforeAnimStart', function () {
    updateSlideCount();
  });

  slider.ev.one('rsAfterSlideChange', function () {
    if (!isFlagship) {
      EventTracking.Adapters.Rails.track_event('imageGalleryViewed', js_listing);
    }
  });

  slider.ev.on('rsAfterSlideChange', function () {
    var slideClasses = $(slider.currSlide.content[0].classList);
    var counter = $('.slideShowCount');
    if ($.inArray('vid', slideClasses) >= 0 && isFlagship) {
      resizeSlider();
    }
    var description = $('.rsGCaption');
    if ($.inArray('tour', slideClasses) >= 0) {
      description.css('display', 'none');
    } else {
      description.css('display', 'block');
    }
    setupCaptions();
    if (slider.isFullscreen) {
      lastActiveSlide = slider.currSlide
    }
  });

  // youtube videos on mobile devices need extra logic to play with just one click
  slider.ev.on('rsOnCreateVideoElement', function (event, url) {
    $('.rsGCaption').css('display', 'none');
    if (/youtu/.test(url) && $.flexmlsUi.isTouch()) {
      autoPlayYoutubeInMobile(url);
    }
  });

  slider.ev.on('rsVideoStop', function () {
    $('.rsGCaption').css('display', 'block');
  });
}

function showCloseGalleryButton() {
  const NATIVE = nativeAppInterface.isNative();
  if (!NATIVE) {
    $("#closeGalleryButtonContainer").show();
  }
}

function hideCloseGalleryButton() {
  const NATIVE = nativeAppInterface.isNative();
  if (!NATIVE) {
    $("#closeGalleryButtonContainer").hide();
  }
}

function displayFullscreenResizeBtn() {
  var fullscreenBtn = $('.royalSlider .rsOverflow').hover(function () {
    fullscreenBtn.removeClass('rsHidden');
  }, function () {
    fullscreenBtn.addClass('rsHidden');
  }).find('.rsFullscreenBtn').addClass('rsHidden')
}

// changes the listing id in the current url so that serving up
// the next set of media is handled by Rails instead of flagship
function updateListingMediaUrl(nextListingId, mod_timestamp, additional_params) {
  if (nextListingId === most_recent_listing_id) {
    if (/jump_to_photo=\d/.test(additional_params)) {
      var query_split = additional_params.split('&');
      for (var i = 0; i < query_split.length; i++) {
        var param_split = query_split[i].split('=');
        if (param_split[0] === 'jump_to_photo' && parseInt(param_split[1],10) > 0) {
          var mediaOffset = js_listing.displayed_video_count + js_listing.displayed_vtour_count + js_listing.displayed_floorplan_count;
          var photoIndex = parseInt(param_split[1],10);
          slider.goTo(photoIndex + mediaOffset)
          break;
        }
      }
    }

    // we're already doing this!
    return;
  }
  most_recent_listing_id = nextListingId;
  var urlBase = window.location.href.split('/listings/')[0];
  var nextListingMediaUrl =
    urlBase + '/listings/' + nextListingId + '/media?size=' + encodeURIComponent(getBestSize());

  if (mod_timestamp !== null && !isNaN(parseInt(mod_timestamp, 10))) {
    nextListingMediaUrl += '&ts=' + mod_timestamp;
  }

  if (additional_params) {
    nextListingMediaUrl += additional_params;
  }

  if (location.href == nextListingMediaUrl) {
    // no action needed if the gallery is already loaded for that listing
    return;
  }
  $('#fullscreen-gallery').fadeOut('fast');
  // if the modal was showing, hide it
  // and while we're at it, remove the content because it definitely
  // no longer applies.
  $('#videoModal').modal('hide').find('videoContent').html('');
  window.showPageLoadingMsg();
  if (!isListingIdValidFormat(nextListingId)) {
    toast.error(
      'There is a problem retrieving the media for the next listing, please refresh the page and try again.',
      false
    );
    return;
  }

  if (xhr) {
    xhr.abort();
  }
  xhr = $.ajax({
    url: nextListingMediaUrl,
    dataType: 'json',
    complete: function () {
      history.replaceState(null, null, nextListingMediaUrl); // stateObj, title, url
    },
    success: function (data, status, xhr) {
      if (data.listing_id !== most_recent_listing_id) {
        return;
      }
      slider.destroy();
      window.hidePageLoadingMsg();
      $('#fullscreen-gallery')
        .finish() // if the fadeOut animation is still running, stop it
        .show()   // if the fadeOut animation was done, flip display from 'none' to 'block'
        .html(data.html_fragment) // then set the HTML

      // Extract tagged listing media
      var taggedListingMedia = $(data.categories_html_fragment).find('#tagged_listing_media').html()
      parsedTaggedListingMedia = taggedListingMedia && JSON.parse(taggedListingMedia)

      ListingGallery.init({ isFlagship: true, bindEvents: false, js_listing: data.js_listing,
                            jump_to_photo: data.jump_to_photo, categories: data.categories_html_fragment,
                            parsedTaggedListingMedia: parsedTaggedListingMedia
                          })
    },
    error: function (xhr, textStatus, errorThrown) {
      if ('abort' !== textStatus) {
        window.hidePageLoadingMsg();
        $('#fullscreen-gallery').show();
        toast.error(
          'There is a problem retrieving the media for the next listing, please refresh the page and try again.',
          false
        );
        window.Bugsnag.notify('gallery_ajax', 'textStatus: ' + textStatus, { error: errorThrown });
      }
    }
  });
  return;
}

// checks that the given listing id only contains numerical characters and is of length 26
// if the given string does not meet the conditions an error message will be displayed
// if nextListingId passes this validation, in the case it is still an invalid id
// our logs will catch it in a 404
function isListingIdValidFormat(nextListingId) {
  if (nextListingId.length != 26 || isNaN(nextListingId)) {
    return false;
  }
  return true;
}

function handleFlagshipFullscreenChange() {
  slider.exitFullscreen();
  resizeSlider();
}

function updateSlideCount(e) {
  var text = slider.currSlideId + 1 + ' / ' + slider.numSlides;
  $('.slideShowCount').text(text);
}

function setupDynamicResizing() {
  $(window).on('resize', function () {
    resizeSlider();
  });
}

function resizeSlider() {
  var slider = $('.royalSlider');
  var sliderData = $('.royalSlider').data('royalSlider');
  if ($.flexmlsUi.isTouch()) {
    slider.css('width', $(sliderData).data('innerWidth'));
  }
  var thumHeight = thumbnailHeightWithScrollbar || DEFAULT_THUMB_HEIGHT;
  var media_nav_height = 0
  if ($('#media-category-nav').length > 0) {
    media_nav_height = DEFAULT_NAV_BAR_HEIGHT + DEFAULT_CONTAINER_PADDING;
  }

  var win = $(window);
  var winHeight = $.flexmlsUi.isTouch()
    ? Number($(sliderData).data('innerHeight'))
    : win.innerHeight();
  slider.css('height', winHeight - thumHeight - media_nav_height);
  $('.rsThumbsHor').css('height', thumHeight);
  slider.data('royalSlider').updateSliderSize(true);
  addShowMoreButtonIfNecessary();
}

function autoPlayYoutubeInMobile(url) {
  setTimeout(function () {
    var player = new YT.Player('player', {
      height: '390',
      width: '640',
      playerVars: { autoplay: 1, playsinline: 1, mute: 1 },
      videoId: url.split('=')[1], // returns just the video id
      events: {
        onReady: onPlayerReady
      }
    });
  }, 100);
  slider.videoObj = $('<div id="player"></div>');
}

// autoplay video
function onPlayerReady(event) {
  event.target.playVideo();
}

function getOS() {
  var platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  }

  return os;
}

function getBestSize() {
  try {
    var retina = window.devicePixelRatio > 1;
    var multiplier = retina ? 2 : 1;
    var height = window.innerHeight * multiplier;
    var width = window.innerWidth * multiplier;

    // Set the defaults to the max width/height
    var arrLastIdx = heightArray.length - 1;
    bestWidth = widthArray[arrLastIdx];
    bestHeight = heightArray[arrLastIdx];
    for (var x = 0; x <= arrLastIdx; x++) {
      // height is the only thing recognized by the CGI script currently.  Therefore, base calcs on height
      if (height <= heightArray[x]) {
        bestWidth = widthArray[x];
        bestHeight = heightArray[x];
        break;
      }
    }

    return '' + bestWidth + 'x' + bestHeight;
  } catch (e) {
    return '';
  }
}

function logPhotoSize() {
  try {
    var payload = {
      retina: window.devicePixelRatio > 1,
      bestHeight: bestHeight,
      bestWidth: bestWidth
    };
    window.Bugsnag.notify(
      'gallery_size',
      '' + payload.bestWidth + 'x' + payload.bestHeight,
      payload
    );
  } catch (e) {
    window.Bugsnag.notify('gallery_size', e.message, e);
  }
}

function setupCaptions() {
  // When slide changes - need to make sure isn't left open from previous
  if ($('.show-more-link').length == 0 || $('.show-more-link').hasClass('open')) {
    closeCaption($('.show-more-link'));
  }

  // Set Caption Max Height
  $('.rsCaption').css('max-height', slider.height);
  $('.rsCaption .text').css('max-height', slider.height - 40);

  // only display show-more-link when the description is truncated (when there actually is more to show)
  addShowMoreButtonIfNecessary();
}

function addShowMoreButtonIfNecessary() {
  var caption = $('.rsDefault .photo-caption .text')[0];
  if (caption && caption.scrollWidth > caption.clientWidth) {
    $('.show-more-link')
      .off()
      .on('click', function () {
        if ($(this).hasClass('open')) {
          closeCaption(this);
        } else {
          openCaption(this);
        }
      });
    $('.show-more-link').show();
  } else {
    if (!$('.photo-caption').hasClass('open-caption')) {
      $('.show-more-link').hide();
    }
  }
}

function openCaption(btn) {
  var caption = $('.rsDefault .photo-caption .text')[0];
  btn = $(btn);
  $('.rsGCaption').addClass('open-caption');
  $('.photo-caption').addClass('open-caption');
  btn.text('Show less');
  btn.addClass('open');
}

function closeCaption(btn) {
  $('.rsGCaption').removeClass('open-caption');
  $('.photo-caption').removeClass('open-caption');
  btn = $(btn);
  btn.text('Show more');
  btn.removeClass('open');
}


// With photo tags, relying on slide index is not safe as the index is regenerated with each tag change
function retainLastActiveSlide(lastActiveSlide) {
  // Skip this function on native. It's throwing an error and causing the fullscreen
  // photo gallery to not display
  if (nativeAppInterface.isNative()) {
    return;
  }

  var lastActiveSlideId

  if (lastActiveSlide.videoURL) {
    lastActiveSlideId = allSlides.findIndex(slide => slide.videoURL == lastActiveSlide.videoURL);
  }
  else if (lastActiveSlide.image) {
    lastActiveSlideId = allSlides.findIndex(slide => slide.image == lastActiveSlide.image);
  }
  else {
    lastActiveSlideId = allSlides.findIndex(slide => slide.content[0].innerHTML == lastActiveSlide.content[0].innerHTML)
  }

  slider.goTo(lastActiveSlideId)

  if(isFlagship){
    loadDelayedImages();
  }
}

export let ListingGallery = {
  init: init
};
