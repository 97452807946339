import { Controller } from 'stimulus';
import { shareListing } from '../../helpers/share_helpers';
import { ListingDetail } from '../../listing_detail';
import { logListingEvent } from '../../listing_event';
import debounce from 'lodash.debounce';
import { consumerListingCardActions } from '../../search_results/consumer_listing_card_actions';

export default class extends Controller {
  js_listing = null;
  listingDetail = null;
  SCROLL_WAIT = 1000;
  SMALL_MOUSE_MOVEMENT = 3;

  async connect(evt) {
    consumerListingCardActions.init();
    this.debouncedAdjustAnchorMenu = debounce(this.adjustAnchorMenu, 100);

    this.js_listing = JSON.parse(this.data.get('listing'));

    // We want to use the ldp_controller, but not all functionality
    // is set up for this page.  Move along.
    if (this.js_listing === null) return;

    let nativeMedia = JSON.parse(this.data.get('listing-native-media'));
    let videos = JSON.parse(this.data.get('listing-videos'));

    this.js_listing.nativeMedia = nativeMedia;
    this.js_listing.videos = [];
    for (let x = 0; x < videos.length; x++) {
      this.js_listing.videos.push(videos[x]);
    }

    window.js_listing = this.js_listing;

    EventTracking.Adapters.Rails.track_event('listingDetailViewed', window.js_listing);
    //    if (this.data.get('ua') == 'shared') {
    //      EventTracking.Adapters.Rails.track_event('listingSharedViaEmail', window.js_listing);
    //    } else if (this.data.get('ua') == 'sent') {
    //      EventTracking.Adapters.Rails.track_event('messageSent', {"Type": "General"});
    //    }

    window.listingDetailData = {
      currencySymbol: this.data.get('currency-symbol'),
      mapEnabled: this.data.get('map-enabled'),
      localLogicKey: this.data.get('local-logic-key'),
      foreignCurrency: JSON.parse(this.data.get('foreign-currency'))
    };

    window.listingActionSettings = {
      crossOrigin: true,
      mobileUrl: this.data.get('mobile-base-url'),
      addSelectedToCarts: 'listings/carts/add_selected_to_carts.json',
      newCustomCart: 'listings/carts/new_custom_cart'
    };

    window.listingEventData = {
      listingId: this.js_listing.ListingKey,
      userId: this.data.get('user-id'),
      userRole: this.data.get('user-role')
    };

    if (!this.data.get('generic-search') == 'true') {
      window.listingEventData.searchContext = this.data.get('search-type-id');
      if (this.data.get('idx-link') == 'true') {
        window.listingEventData.idxLinkId = this.data.get('search-type-id');
      }
    }

    if (typeof window.listingEventData !== 'undefined') {
      logListingEvent(window.listingEventData);
    }

    ListingDetail.init(window.js_listing, window.listingDetailData);

    // We dont need the ListingDetail shareLink listener and if we dont delete it
    // it will cause errors on the underlying list items if you use the 3dot menu.
    $('.shareLink').off('click');

    // If we can find the modal with the prev/next information, read that and set the prev/next buttons.
    // If not, hide the prev/next buttons.
    let parentNavigationController = $(this.element).closest('[data-controller=ldp-navigation]');
    if (parentNavigationController.length == 0) {
      $('#previous_listing, #next_listing').addClass('hidden');
    } else {
      $('#previous_listing, #next_listing').removeClass('hidden');
      $('#previous_listing, #next_listing').attr(
        'navsource',
        $(parentNavigationController[0]).attr('data-ldp-navigation-src')
      );
      if ($(parentNavigationController[0]).attr('data-ldp-navigation-prev') == 'true') {
        $('#previous_listing').removeClass('nomore');
      } else {
        $('#previous_listing').addClass('nomore');
      }

      if ($(parentNavigationController[0]).attr('data-ldp-navigation-next') == 'true') {
        $('#next_listing').removeClass('nomore');
      } else {
        $('#next_listing').addClass('nomore');
      }
    }

    this.pageCleanup();
    $(document).trigger('flexPanel:ldpLoaded');
  }

  pageCleanup() {
    this.removeAnchorsWithNoTarget();
    this.removeSequentialSectionSeparators();
  }

  removeAnchorsWithNoTarget() {
    // Find all buttons in scrollmenu and remove the buttons whose href is not on the page.
    $('.scrollMenu button[href]')
      .filter(function () {
        var hrefValue = $(this).attr('href');
        // Return true if the href value can be found on the page
        console.log(hrefValue + ': ' + $(hrefValue).length);
        return $(hrefValue).length === 0;
      })
      .remove();
  }

  removeSequentialSectionSeparators() {
    // If the content has two or more section separators in a row, we must have empty space.
    // Make sure there is only 1 separator
    // Iterate over child elements of content div
    $('#ldpContent > div').each(function (index) {
      var currentDiv = $(this);
      var nextDiv = currentDiv.next();

      // Check if both current and next div have the specified class
      while (
        currentDiv.hasClass('c-section__separator') &&
        nextDiv.hasClass('c-section__separator')
      ) {
        // Remove one of the divs
        nextDiv.remove();
        // Update the nextDiv reference for further checking
        nextDiv = currentDiv.next();
      }
    });
  }

  jumpToAnchor(evt) {
    evt.preventDefault();
    if ($('.scrollMenu').hasClass('active')) {
      // We appear to be in a drag event.  Dont process a click.
      return;
    }

    // Make sure the menu item is fully in view
    $('.scrollMenu div').removeClass('active');
    // The new LDP uses a button instead of a div
    $('.scrollMenu button').removeClass('active');

    let targetDiv = $(evt.target).parent();
    if ($(evt.target).prop('tagName') === 'BUTTON') {
      // NEW LDP
      targetDiv = $(evt.target);
    }
    let scrollMenu = $('.scrollMenu');
    targetDiv.addClass('active');
    if (targetDiv[0].offsetLeft < $('.scrollMenu').scrollLeft()) {
      scrollMenu.scrollLeft(targetDiv.position().left);
    } else {
      let targetDivRight = targetDiv[0].offsetLeft + targetDiv.outerWidth();
      let scrollMenuRight = scrollMenu.scrollLeft() + scrollMenu.outerWidth();
      if (targetDivRight > scrollMenuRight) {
        scrollMenu.scrollLeft(scrollMenu.scrollLeft() + (targetDivRight - scrollMenuRight));
      }
    }

    // Now jump to the proper place on the page
    let jumpPointSelector = $(evt.currentTarget).attr('href');
    let scrollContainer = $('.ldp-content')[0];
    let scrollToElement = $(jumpPointSelector)[0];
    scrollContainer.scrollTop = scrollToElement.offsetTop - scrollContainer.offsetTop;
  }

  contentScrolling(evt) {
    this.debouncedAdjustAnchorMenu();
  }

  adjustAnchorMenu(evt) {
    // If there is a clicked button, we are here because of
    // a menu click.  Ignore the scroll event
    let clickedMenuItem = $('.scrollMenu button.clicked');
    if (clickedMenuItem.length > 0) {
      return;
    }

    let scrollDirection = 0; // Down (-1 == up)
    //Check to see if the active menu item's target is visible.
    let activeMenuItem = $('.scrollMenu button.active');
    if (activeMenuItem.length > 0) {
      //There is an active menu item.  Lets check its positioning.
      let currentTarget = $(activeMenuItem.first().attr('href'));
      let relativeTop = $(currentTarget).position().top - $('#ldpContent').position().top;

      let scrollingData = this.calculateVisiblePercentage($(currentTarget), $('#ldpContent'));
      if (scrollingData.visiblePercentage >= 50) {
        return;
      }
      scrollDirection = scrollingData.scrollDirection;
    }
    // The active menu item's target is out of the scroll view.  Pick the first item whose target is visible in the top of the screen.
    $('.scrollMenu button').removeClass('active');

    let jumpTargetLeft = 0;
    let jumpTargetIndex = -1;

    // Loop through the scroll menu items
    $('.scrollMenu button').each(index => {
      let scrollingData = this.calculateVisiblePercentage(
        $($('.scrollMenu button')[index].getAttribute('href')),
        $('#ldpContent')
      );
      if (scrollingData.visiblePercentage >= 50) {
        jumpTargetIndex = index;
        if (scrollDirection == 0) {
          // We are scrolling down and looking for the first item that is at least 50% visible.
          // Break out of the eaqch loop.
          return false;
        } // Else scrolling up, keep going until we find the LAST qualified item
      }
    });

    // We should have a target that we want to use now.
    if (jumpTargetIndex >= 0) {
      // This is the droid we are looking for!
      let targetMenuItem = $($('.scrollMenu button')[jumpTargetIndex]);
      let scrollMenu = $('.scrollMenu');

      // Set the menu item as active
      targetMenuItem.addClass('active');

      // Make sure the menu item is totally visible in the nav ribbon
      if (targetMenuItem[0].offsetLeft < $('.scrollMenu').scrollLeft()) {
        scrollMenu.scrollLeft(jumpTargetLeft);
      } else {
        let targetDivRight = targetMenuItem[0].offsetLeft + targetMenuItem.outerWidth();
        let scrollMenuRight = scrollMenu.scrollLeft() + scrollMenu.outerWidth();
        if (targetDivRight > scrollMenuRight) {
          scrollMenu.scrollLeft(scrollMenu.scrollLeft() + (targetDivRight - scrollMenuRight));
        }
      }
    }
  }

  ldpShareListing(evt) {
    shareListing(evt);
  }

  calculateVisiblePercentage($target, $container) {
    let scrollingData = {
      scrollDirection: 0,
      visiblePercentage: 0
    };
    var containerTop = $container.offset().top;
    var containerBottom = containerTop + $container.height();
    var targetTop = $target.offset().top;
    var targetBottom = targetTop + $target.height();

    if (targetTop < containerTop && targetBottom > containerBottom) {
      // The target is bigger than the viewing area and is taking up the entire viewing area.
      scrollingData.visiblePercentage = 100;
      return scrollingData;
    }

    if (targetBottom < containerTop) {
      // The target is scrolled out of view to the top
      scrollingData.visiblePercentage = 0;
      return scrollingData;
    }

    if (targetTop > targetBottom) {
      // The target is scrolled out of view to the bottom
      scrollingData.visiblePercentage = 0;
      scrollingData.scrollDirection = -1;
      return scrollingData;
    }

    if (targetBottom > containerBottom) {
      scrollingData.scrollDirection = -1;
    }

    var visibleTop = Math.max(
      0,
      Math.min(containerBottom, targetBottom) - Math.max(containerTop, targetTop)
    );
    scrollingData.visiblePercentage = (visibleTop / $target.height()) * 100;
    return scrollingData;
  }
}
