import { Controller } from 'stimulus';

import { nativeAppInterface } from '../native_app_interface';

export default class extends Controller {
  static targets = [ "tag", "category" ]

  activeCategoryTab = "combined";  // Top level media category.  "combined" for backwards compatability with views not using the top level categories
  activeMediaTag = undefined;      // Sub level media tags.

  async connect(evt) {
    var royal_slider = $('.royalSlider').data('royalSlider');

    if (!royal_slider) {
      $(this).on("ready", this.setupGallery )
    } else {
      this.setupGallery()
    }
  }

  setupGallery() {
    var royal_slider = $('.royalSlider').data('royalSlider');
    royal_slider.ev.on('rsEnterFullscreen', this.enterFullscreenGallery.bind(this));
    royal_slider.ev.on('rsExitFullscreen', this.exitFullscreenGallery.bind(this));

    if (this.hasCategoryTarget) {
      $(this.categoryTargets[0]).parent().addClass("active");
      this.activeCategoryTab = $(this.categoryTargets[0]).data("media-gallery-category-param")
    }
    this.switchActiveMedia(this.activeCategoryTab);
  }

  // Change top-level active media category via nav buttons
  activateMediaCategory({params : {category }}) {
    $('.category-nav').removeClass("active");
    $('.category-nav-' + category).addClass("active");
    this.switchActiveMedia(category);
  }

  // Change top-level active media category via select (mobile)
  selectMediaCategory(evt) {
    let category = evt.target.value
    this.switchActiveMedia(category);
  }

  // Change sub-level active media tag
  selectMediaTag({ params: { tag }}) {
    this.switchActiveTag(this.activeCategoryTab, tag);
  }

  // Update media category button display and then update the slides
  switchActiveMedia(category) {
    var tag_count = 0;
    this.tagTargets.forEach(function(tag) {
        if ($(tag).hasClass("category-"+category)) {
            $(tag).parent().removeClass("hidden");
            tag_count++;
        } else {
            $(tag).parent().addClass("hidden");
        }
    })
    if (tag_count < 2) {
        $(".full-screen-gallery").addClass("no-tag-nav");
        $(".full-screen-gallery .listing-tags-nav").addClass("hidden");
    } else {
        $(".full-screen-gallery").addClass("has-tag-nav");
        $(".full-screen-gallery .listing-tags-nav").removeClass("hidden");
    }
    this.switchActiveTag(category, "All");
  }

  // Update the slides
  switchActiveTag(category, tag) {
    let categorySlider = $('.royalSlider').data('royalSlider');
    let numSlides = categorySlider.numSlides;
    var taggedMedia = JSON.parse($('#tagged_listing_media').html());

    if (this.hasTagTarget) {
        this.tagTargets.forEach(function(t) {
            if ($(t).hasClass("category-"+category) && $(t).hasClass("tag-"+tag))
                $(t).parent().addClass("active");
            else
                $(t).parent().removeClass("active");
        })
    }

    if (this.activeCategoryTab != category || this.activeMediaTag != tag) {
      // Append slides based on clicked tag
      taggedMedia[category][tag].forEach((media, index) => {
        if (media?.primary) {
          categorySlider.appendSlide(media.html, numSlides);
        } else {
          categorySlider.appendSlide(media.html);
        }
      });

      // Remove existing slides
      for (let i = 0; i < numSlides; i++) {
        categorySlider.removeSlide(0);
      }

      // Handles a case when first caption appear out of sync on tag change
      var captions = categorySlider.slides[0].caption;
      captions && categorySlider.globalCaption.html(captions[0]);

      this.activeMediaTag = tag;
      this.activeCategoryTab = category;
    }
    $('.rsTmb.photo.delayed').removeClass('delayed');
  }

  enterFullscreenGallery() {
    if(nativeAppInterface.isNative()){
      return;
    }
    
//    if ($('.rsOverflow').find('.listing-tags-nav').length === 0) {
      // This was previously added in server side generated javascript.
      // Now we add it to the LDP and when the gallery goes full screen
      // for the first time we move the hidden item into the royal slider
      // then unhide it (we are already full screen) and then make sure
      // the first tab is selected.
      // No.  Add it to the proper royalslider element
      $('.listing-tags-nav').removeClass('hidden');
      $('#All').parent('li').addClass('active');
  //  }
    $('.listing-detail-content-column.flexpanel').addClass('full-screen-gallery');
    $('#fullscreen-gallery').addClass('full-screen-gallery');
  }

  exitFullscreenGallery() {
      $('.listing-tags-nav').addClass('hidden');
    $('.listing-detail-content-column.flexpanel').removeClass('full-screen-gallery');
    $('#fullscreen-gallery').removeClass('full-screen-gallery');
  }

}