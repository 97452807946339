import doLineSegmentsIntersect from './line_segments_intersect'
import mapConverters from './map_converters'
import mapEvents from './map_events'

export const defaultShapeOptions = {
  fillOpacity: 0.4,
  strokeWeight: 1,
  clickable: true
};

export default class DrawingManager {

  constructor(googleMap, markerManager) {
    this.drawings = [];
    this.googleMap = googleMap;
    this.markerManager = markerManager;
    // We draw our own circles now.  This is our holder for the circle in progress.
    this.circleInProgress = null;
    
    this._drawingInProgress = false;
    this._drawingsVisible = true;
    this.selectedShape = null;
    var self = this;

    // When the user clicks on the map, a dropdown, a button or a checkbox, remove any shape context menu that might be visible.
    this.googleMap.addListener('click',this.resetShapesMenu.bind(this));
    this.googleMap.addListener('drag',this.resetShapesMenu.bind(this));
    this.googleMap.addListener('zoom_changed',this.resetShapesMenu.bind(this));
    $(document).on('shown.bs.dropdown', this.resetShapesMenu.bind(this));
    $('button:not(#deleteDrawingBtn)').on('click',this.resetShapesMenu.bind(this));
    $('input:checkbox').on('click',this.resetShapesMenu.bind(this));

  }

  resetShapesMenu() {
    $('#shapeContextMenu').css({ display: 'none'});
    this.selectedShape = null;
    this.resetAllShapesOpacity();
  }

  resetAllShapesOpacity() {
    this.drawings.forEach( function(shape) {
      var fillOpacity = mapConverters.getShapePropertiesByColor(shape.get('fillColor')).fillOpacity;
      shape.set('fillOpacity',fillOpacity);
    })
  }
  
  resetAllShapesClickability(clickable) {
    this.drawings.forEach( function(shape) {
      shape.setOptions({clickable: clickable});
    });
  }
  
  hasDrawings() {
    return this.drawings.length > 0;
  }

  drawingsVisible() {
    return this._drawingsVisible;
  }

  displayShapeContextMenu(x,y) {
      var windowWidth = $(window).width();
      var windowHeight = $(window).height();
      var menuWidth = $('#shapeContextMenu').width();
      var menuHeight = $('#shapeContextMenu').height();

      if (x + menuWidth > windowWidth) {
        x -= menuWidth;
      }

      if (y + menuHeight > windowHeight) {
        y -= menuHeight;
      }
      
      $('#shapeContextMenu').css({ left: x + 'px', top: y + 'px', display: 'block', 'z-index': 500});
    }

  add(shape, annotation) {
    shape.visible = this._drawingsVisible;
    if (annotation) {
      var shapeProperties = mapConverters.getShapePropertiesByColor(annotation.Attributes.Color);
      // Use the shapeProperties fill color in case the annotation color was null.
      shape.set('fillColor', shapeProperties.fillColor);
      shape.set('strokeColor', shapeProperties.strokeColor);
      shape.set('strokeWeight', shapeProperties.strokeWeight);
      shape.set('strokeOpacity', shapeProperties.strokeOpacity);
      shape.set('fillOpacity', shapeProperties.fillOpacity);
      shape.set('shapeName', annotation.Attributes.Label);
    }

    this.drawings.push(shape);
    shape.setMap(this.googleMap);
    var self = this;
    shape.addListener('click', function(event) {
      if (self.selectedShape != null && self.selectedShape !== shape) {
        // If another shape was selected and displaying the context menu
        // set the other shape's opacity back to normal.
        self.selectedShape.set('fillOpacity', 0.5);
      }
      self.selectedShape = shape;
      shape.set('fillOpacity', 0.7);
      self.displayShapeContextMenu(event.domEvent.pageX, event.domEvent.pageY);
    });
    shape.addListener('mouseover', function(event) {
        if (!self._drawingMode) {
          shape.set('fillOpacity', 0.7);
        }
    });
    shape.addListener('mouseout', function(event) {
      if (shape !== self.selectedShape){
        shape.set('fillOpacity', 0.5);
      }
      // Else the shape is the one associated with the context menu.
      // Keep that hightlighted until the context menu is closed.
    });
  }

  deleteDrawing(shape) {
    shape.visible = false;
    var index = this.drawings.indexOf(shape);
    if (index >= 0) {
      shape.visible = false;
      shape.setMap(null);
      this.drawings.splice(index, 1);
    }
  }

  toggleVisibility(visible) {
    var self = this;    
    var oldDrawings = this.drawings;
    this.deleteAll();

    oldDrawings.forEach((drawing) => {
      drawing.visible = visible;
      self.drawings.push(drawing);
      drawing.setMap(self.googleMap);
    });
    this._drawingsVisible = visible;
  }

  getBoundsForMultipleShapes() {
    var bounds = null;
    if (this.drawings.length === 0) {
      return null;
    }
    const combinedBounds = new google.maps.LatLngBounds();

    this.drawings.forEach((drawing) => {
      if (drawing instanceof google.maps.Polygon) {
        // Extend bounds with every polygon vertex
        drawing.getPath().forEach(latLng => combinedBounds.extend(latLng));
      } else if (drawing instanceof google.maps.Rectangle) {
        // Clone rectangle bounds to avoid reference issues
        const rectBounds = drawing.getBounds();
        const sw = rectBounds.getSouthWest();
        const ne = rectBounds.getNorthEast();
        combinedBounds.union(new google.maps.LatLngBounds(sw, ne));
      } else {
        // For circles/polylines/markers, use default bounds
        combinedBounds.union(drawing.getBounds());
      }
    });
    return combinedBounds;
  }

  deleteAll() {
    if(this.drawings.length > 0) {
      this.drawings.forEach(function(drawing){
        drawing.setMap(null);
      });
      this.drawings = [];
    }
  }


  drawingInProgress() {
    return this._drawingInProgress;
  }

  startDrawing(shape) {
    switch(shape) {
      case 'polygon':
        this.startDrawingPolygon();
        break;
      case 'circle':
        this.startDrawingCircle();
        break;
      case 'rectangle':
        this.startDrawingRectangle();
        break;
    }
  }

  startDrawingPolygon() {
    this._drawingInProgress = true;
    this._drawingMode = google.maps.drawing.OverlayType.POLYGON;
    let polygonProperties = mapConverters.getNextShapeProperties();
    let polygonOptions = $.extend({}, defaultShapeOptions, polygonProperties);
    // Use these settings only while drawing
    polygonOptions.strokeWeight = mapConverters.defaultDrawingProperties.strokeWeight;
    polygonOptions.strokeOpacity = mapConverters.defaultDrawingProperties.strokeOpacity;

    // We are only making the polygon editable so that the handle will appear
    // after the first click. Otherwise, the user doesn't get any feedback
    // that their click has added a point. Editing is turned off in the
    // polygoncomplete handler.
    polygonOptions.editable = true;

    if(typeof this.googleDrawingManager === 'undefined'){
      this.googleDrawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: this._drawingMode,
        drawingControl: false,
        polygonOptions: polygonOptions
      });
    }

    if(typeof this._shapeCompleteListener === 'undefined') {
      this._shapeCompleteListener = google.maps.event.addListener(
        this.googleDrawingManager, 
        'polygoncomplete', 
        this._polygonCompleteHandler.bind(this)
      );
    }

    this.googleDrawingManager.setDrawingMode(this._drawingMode);
    this.googleDrawingManager.setMap(this.googleMap);
  }

  startDrawingRectangle() {
    this._drawingInProgress = true;
    this._drawingMode = google.maps.drawing.OverlayType.RECTANGLE;
    let rectangleProperties = mapConverters.getNextShapeProperties();
    let rectangleOptions = $.extend({}, defaultShapeOptions, rectangleProperties);
    // Use these settings only while drawing
    rectangleOptions.strokeWeight = mapConverters.defaultDrawingProperties.strokeWeight;
    rectangleOptions.strokeOpacity = mapConverters.defaultDrawingProperties.strokeOpacity;

    // We are only making the rectangle editable so that the handle will appear
    // after the first click. Otherwise, the user doesn't get any feedback
    // that their click has added a point. Editing is turned off in the
    // rectanglecomplete handler.
    rectangleOptions.editable = true;

    if(typeof this.googleDrawingManager === 'undefined'){
      this.googleDrawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: this._drawingMode,
        drawingControl: false,
        rectangleOptions: rectangleOptions
      });
    }

    if(typeof this._shapeCompleteListener === 'undefined') {
      this._shapeCompleteListener = google.maps.event.addListener(
        this.googleDrawingManager, 
        'rectanglecomplete', 
        this._rectangleCompleteHandler.bind(this)
      );
    }

    this.googleDrawingManager.setDrawingMode(this._drawingMode);
    this.googleDrawingManager.setMap(this.googleMap);
  }

  startDrawingCircle() {
    this._drawingInProgress = true;
    this._drawingMode = google.maps.drawing.OverlayType.CIRCLE;

    // Freeze the map
    this.googleMap.setOptions({draggable: false});
    // Make sure we can click on existing shapes without interfacing with them.
    this.resetAllShapesClickability(false);
    // Start listening for the user to click to define the center of the circle.
    this.circleMouseDownListener = this.googleMap.addListener('mousedown', this.startDrawingACircleWithRadius.bind(this));
    $("#mapContainer").css("cursor", "drawing-in-progress");
  }


  cancelDrawing() {
    // If we are drawing a circle, there is some special cleanup
    this.cleanupCircleDrawingMode();

    google.maps.event.removeListener(this._shapeCompleteListener);
    delete this._shapeCompleteListener;

    this._drawingInProgress = false;
    if (this.googleDrawingManager) {
      // erase an incomplete drawing
      this.googleDrawingManager.setMap(null);
      // exit drawing mode
      this.googleDrawingManager.setDrawingMode(null);
      this.googleDrawingManger = null;
      delete this.googleDrawingManager;
    }
    this._drawingMode = null;
    window.dispatchEvent(mapEvents.drawingComplete());
  }

  _polygonCompleteHandler(polygon) {
    var pointsArray = polygon.getPath();

    if(this._validatePath(pointsArray)){
      this._drawingInProgress = false;

      polygon.setEditable(false);
      polygon.set('shapeName', 'Polygon');
      var shapeProperties = mapConverters.getShapePropertiesByColor(polygon.get('fillColor'));
      //change drawing mode only options back to regular options
      polygon.set('strokeWeight',shapeProperties.strokeWeight);
      polygon.set('strokeOpacity', shapeProperties.strokeOpacity);

      this.add(polygon);

      this.googleDrawingManager.setDrawingMode(null);
      this._drawingMode = null;

      window.dispatchEvent(mapEvents.drawingComplete(polygon));

      google.maps.event.removeListener(this._shapeCompleteListener);
      delete this._shapeCompleteListener;
      this.googleDrawingManager = null;
      delete this.googleDrawingManager;

    } else {
      polygon.setMap(null);
      window.dispatchEvent(mapEvents.drawingError());
    }
  }

  _rectangleCompleteHandler(rectangle) {
    var minDistance = 25.0;
    this._drawingInProgress = false;

    rectangle.setEditable(false);

    // Make sure that a minimal sized rectangle has been drawn.  And enlarge
    // the rectangle if it is too small.
    var distance = google.maps.geometry.spherical.computeDistanceBetween(rectangle.getBounds().getNorthEast(), rectangle.getBounds().getSouthWest());
    while (distance < minDistance) {
      // 0.000001 is the smallest meaningful lat/lng unit (for our purposes).  We are moving the 2 corners to try to get a
      // rectangle that is also meaningful and does not break the system (25 meters corner to corner for now).
      var southWest = new google.maps.LatLng(rectangle.getBounds().getSouthWest().lat() - 0.000001, rectangle.getBounds().getSouthWest().lng() - 0.000001);
      var northEast = new google.maps.LatLng(rectangle.getBounds().getNorthEast().lat() + 0.000001, rectangle.getBounds().getNorthEast().lng() + 0.000001);
      rectangle.setBounds(new google.maps.LatLngBounds(southWest, northEast));
      distance = google.maps.geometry.spherical.computeDistanceBetween(rectangle.getBounds().getNorthEast(), rectangle.getBounds().getSouthWest());
    }

    rectangle.set('shapeName', "Rectangle");
    //change drawing mode only options back to regular options
    var shapeProperties = mapConverters.getShapePropertiesByColor(rectangle.get('fillColor'));
    rectangle.set('strokeWeight',shapeProperties.strokeWeight);
    rectangle.set('strokeOpacity', shapeProperties.strokeOpacity);

    this.add(rectangle);

    this.googleDrawingManager.setDrawingMode(null);
    this._drawingMode = null;

    window.dispatchEvent(mapEvents.drawingComplete(rectangle));

    google.maps.event.removeListener(this._shapeCompleteListener);
    delete this._shapeCompleteListener;
    this.googleDrawingManager = null;
    delete this.googleDrawingManager;
  }

  _circleCompleteHandler(circle) {
      this._drawingInProgress = false;
      circle.setEditable(false);

      circle.set('shapeName', 'Circle');
      //change drawing mode only options back to regular options
      var shapeProperties = mapConverters.getShapePropertiesByColor(circle.get('fillColor'));
      circle.set('strokeWeight',shapeProperties.strokeWeight);
      circle.set('strokeOpacity', shapeProperties.strokeOpacity);

      this.add(circle);

      if (this.googleDrawingManager) {
        this.googleDrawingManager.setDrawingMode(null);
      }
      this._drawingMode = null;

      window.dispatchEvent(mapEvents.drawingComplete(circle));

      google.maps.event.removeListener(this._shapeCompleteListener);
  }

  _validatePath(googlePointsArray){

    if(googlePointsArray.length < 3){
      return false;
    }

    var pointsArray = mapConverters.googlePointsToXYPoints(googlePointsArray);

    for (var i=0; i < (pointsArray.length - 1); i++) {

      var a = pointsArray[i];
      var b = pointsArray[i+1];

      for (var j=i; j < (pointsArray.length - 2); j++) {
        var c = pointsArray[j+2];
        var d;
        if (j+3 < pointsArray.length){
          d = pointsArray[j+3];
        } else if (i !== 0 ) {
          d = pointsArray[0];
        } else {
          continue;
        }

        if(doLineSegmentsIntersect(a, b, c, d)) {
          return false;
        }

      }
    }

    return true;
  }

  startDrawingACircleWithRadius(event) {
    // Get desired circle properties
    let circleProperties = mapConverters.getNextShapeProperties();
    let circleOptions = $.extend({}, defaultShapeOptions, circleProperties);
    circleOptions.strokeWeight = mapConverters.defaultDrawingProperties.strokeWeight;
    circleOptions.strokeOpacity = mapConverters.defaultDrawingProperties.strokeOpacity;
    circleOptions.center = event.latLng;
    // Start the circle with a very small radius
    circleOptions.radius = 1.0;
    // Note the time.  This will help with accidental click-while-dragging hits.
    // Basically, if the user wants to define the circle by clicking then moving the mouse then clicking
    // again (instead of mousedown, drag, mouseup) we need to make sure any unintentional drag during the
    // click did not happen.
    this.circleCreationStartTime = new Date();

    // Create the circle, add it to the map and make it look like a circle in progress.
    this.circleInProgress = new google.maps.Circle(circleOptions);
    this.circleInProgress.setMap(this.googleMap);
    this.circleInProgress.setOptions({ clickable: false});

    // Add the new radius marker at the right-most edge of the circle.
    this.markerManager.addRadiusMarker(this.circleInProgress);

    // Listen for other mouse events
    this.circleMouseMoveListener = this.googleMap.addListener('mousemove', this.updateNewCircleRadius.bind(this));
    this.circleMouseUpListener = this.googleMap.addListener('mouseup', this.stopDrawingNewCircle.bind(this));

    // No need to listen for mouse down anymore.
    google.maps.event.removeListener(this.circleMouseDownListener);
  }

  updateNewCircleRadius(event) {
    // Process mouse movements while circle is being drawn
    if (this.circleInProgress) {
      // If drawing a circle, calculate the new radius based on where the mouse is...
      var radius = google.maps.geometry.spherical.computeDistanceBetween(this.circleInProgress.getCenter(), event.latLng);
      this.circleInProgress.setRadius(radius);
      // ...and update the radius marker.
      this.markerManager.editRadiusMarker(this.circleInProgress);
    }
  }

  stopDrawingNewCircle(event) {
    // Mouse up event
    // How lon has the mouse been down?
    var dragTime = ((new Date()).getTime() - this.circleCreationStartTime.getTime());
    // How far from the center point is the mouse now?
    var radius = google.maps.geometry.spherical.computeDistanceBetween(this.circleInProgress.getCenter(), event.latLng);

    // If user clicked (instead of mouse down to drag) keep drawing.
    if (radius <= 1.0) return;
    // Did the user mean to click instead of drag?  (These values may need to be tweeked.  But they work well for me.)
    if (dragTime < 1000 && radius < 30) return;

    // Looks like we really are done drawing the circle.  Set the radius.
    this.circleInProgress.setRadius(radius);

    var circle = this.circleInProgress;
    circle.setOptions({clickable: true});
    this._circleCompleteHandler(circle);
    this.circleInProgress = null;
    this.cleanupCircleDrawingMode();
  }

  startDrawingCircle() {
    this._drawingInProgress = true;
    this._drawingMode = google.maps.drawing.OverlayType.CIRCLE;

    this.googleMap.setOptions({draggable: false});
    this.resetAllShapesClickability(false);
    this.circleMouseDownListener = this.googleMap.addListener('mousedown', this.startDrawingACircleWithRadius.bind(this));
  }

  cleanupCircleDrawingMode() {

    if (this.circleInProgress != null) {
      this.markerManager.removeShapeMarker(this.circleInProgress);
      this.circleInProgress.setMap(null);
    }
    google.maps.event.removeListener(this.circleMouseDownListener);
    google.maps.event.removeListener(this.circleMouseMoveListener);
    google.maps.event.removeListener(this.circleMouseUpListener);

    this.googleMap.setOptions({draggable: true});

    this.circleInProgress = null;
    this.resetAllShapesClickability(true);
  }

  highlightShape(shapeDef) {
    let self = this;
    this.drawings.forEach(function (shape){
      if (self.shapeDefsEqual(shape.condition,shapeDef)) {
        shape.set('fillOpacity',0.9);
        return;
      }
    });
  }

  unhighlightShape(shapeDef) {
    let self = this;
    this.drawings.forEach(function (shape){
      if (self.shapeDefsEqual(shape.condition,shapeDef)) {
        shape.set('fillOpacity',0.5);
        return;
      }
    });
  }

  shapeDefsEqual(shapeDef1, shapeDef2) {
    return (shapeDef1.replaceAll(', ', ',') === shapeDef2.replaceAll(', ', ','))
  }
}
