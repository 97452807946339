// We use this as our central source for native interface for this folder/directory
// NOTE: We cannot import from app/assets/javascripts

// Helper Functions ---------------------------------------------------------------

// Checks whether we are in the native webview
// On the Android app, there is an object com_flexmls_native that will be
//   available on its webview
function isNative() {
  return typeof com_flexmls_native !== 'undefined';
}

// Gets the preferences
//   preferenceName: string -> name (key) of the preference value
function getPreferences(preferenceName) {
  if (isNative() && typeof com_flexmls_native.getPreferences === 'function') {
    return com_flexmls_native.getPreferences(preferenceName);
  } else {
    return '';
  }
}

// Emits/triggers the event back to native
//  eventName: string -> name of the event
//  data: string -> stringified json data to send to native
//  callback: function -> function to call before triggering the event to native
function emitNativeEvent(eventName, data, callback) {
  if (isNative() && typeof com_flexmls_native.emitNativeEvent === 'function') {
    if (typeof callback === 'function') {
      callback();
    }
    com_flexmls_native.emitNativeEvent(eventName, data);
  }
}

function isFeatureEnabled(feature) {
  var ff = getPreferences('FEATURE_FLAGS');
  if (ff.length !== 0 && typeof feature === 'string') {
    var featureFlags = JSON.parse(ff);
    if (typeof featureFlags[feature] !== 'undefined') {
      return featureFlags[feature];
    }
  }

  return false;
}

function isReactNativeFilterScreenEnabled() {
  return isFeatureEnabled('NEW_FILTERS') && isFeatureEnabled('NEW_FILTERS_SCREEN');
}

function isReactNativeMediaCategoriesEnabled() {
  return isFeatureEnabled('MEDIA_CATEGORIES');
}

function isReactNativeFlexPanelSearchResultsEnabled() {
  return isFeatureEnabled('FLEX_PANEL_SEARCH_RESULTS');
}

// End Helper Functions -----------------------------------------------------------

function enterGalleryFullScreen({ currentIndex, numberOfImages, callback, currentMediaCategory } = {}) {
  emitNativeEvent(
    'GO_NATIVE_DISPLAY_FULLSCREEN_IMAGE_GALLERY',
    JSON.stringify({
      ListingId: js_listing.ListingId,
      ListingKey: js_listing.ListingKey,
      index: currentIndex,
      currentMediaCategory: currentMediaCategory,
      numberOfImages,
      videos: js_listing.nativeMedia.Videos,
      /**
       * For backwards-compability reasons, the Android app will be expecting the photos to be inside an 'attributes' key
       */
      photos: js_listing.nativeMedia.Photos.map(photo => ({ attributes: photo })),
      /**
       * For backwards-compability reasons, old versions the Android app will be expecting floorplans to be inside of floplans
       */
      floplans: js_listing.nativeMedia.FloorPlans.map(floorPlan => {
        const floPlan = {
          attributes: {
            Id: floorPlan.Id,
            Name: floorPlan.Caption,
            ResourceUri: floorPlan.Uri,
            Images: [
              {
                Uri: floorPlan.Uri,
                Type: floorPlan.Type
              }
            ]
          }
        };

        return floPlan;
      }),
      floorPlans: js_listing.nativeMedia.FloorPlans,
      virtualTours: js_listing.nativeMedia.VirtualTours
    }),
    callback
  );
}

function displayFilterScreen({
  filterString,
  quickSearchId,
  savedSearchId,
  comparableSearchId,
  shapes,
  isInitialLoad = false,
  updateOnly = false,
} = {}) {
  if (isReactNativeFilterScreenEnabled()) {
    emitNativeEvent(
      'GO_NATIVE_DISPLAY_FILTER_SCREEN',
      JSON.stringify({
        filterString,
        quickSearchId,
        savedSearchId,
        comparableSearchId,
        shapes,
        isInitialLoad,
        updateOnly
      }),
      null
    );
  }
}

function updateSelectedCart(selectedCardCount) {
  if (isNative() && typeof com_flexmls_native.updateSelectedCart === 'function') {
    com_flexmls_native.updateSelectedCart(selectedCardCount);
  }
}

function photoGalleryDidDisplay() {
  if (isNative() && typeof com_flexmls_native.photoGalleryDidDisplay === 'function') {
    com_flexmls_native.photoGalleryDidDisplay();
  }
}

function photoGalleryDidClose() {
  if (isNative() && typeof com_flexmls_native.photoGalleryDidClose === 'function') {
    com_flexmls_native.photoGalleryDidClose();
  }
}

function enterFullScreenVideoVirtualTour({ uri, video } = {}) {
  emitNativeEvent(
    'GO_NATIVE_DISPLAY_FULLSCREEN_VIDEO_VIRTUAL_TOUR',
    JSON.stringify({
      uri: uri,
      video: video
    })
  );
}

export let nativeAppInterface = {
  enterGalleryFullScreen: enterGalleryFullScreen,
  updateSelectedCart: updateSelectedCart,
  photoGalleryDidDisplay: photoGalleryDidDisplay,
  photoGalleryDidClose: photoGalleryDidClose,
  isFeatureEnabled: isFeatureEnabled,
  isReactNativeFilterScreenEnabled: isReactNativeFilterScreenEnabled,
  isReactNativeMediaCategoriesEnabled: isReactNativeMediaCategoriesEnabled,
  isReactNativeFlexPanelSearchResultsEnabled: isReactNativeFlexPanelSearchResultsEnabled,
  displayFilterScreen: displayFilterScreen,
  enterFullScreenVideoVirtualTour: enterFullScreenVideoVirtualTour,
  isNative: isNative
};
